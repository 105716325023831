// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";

import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "./ViewPOIssued";
import CustomButton2 from "../../general-components/CustomButton2.component";

export default function ManagePOIssuedView({ onCancel, selectedValue }) {
    let [checkboxAdd, setCheckboxAdd] = useState(true);
    const [openAccordionIndex, setOpenAccordionIndex] = useState([1]);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">View PI Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">

                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                </div>
                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    )
}
