// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewLC({ selectedValue }) {
  const plR_IsPaymentOrLCClosed = selectedValue?.plR_IsPaymentOrLCClosed ? 'True' : 'False';
  const [imageError, setImageError] = useState({});

  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const formatDateTest1 = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // for 24-hour format
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <>
      <div>
        <div className=" max-w-full w-[1000px]">
          {selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0 ? (
            selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map((item, index) => (
              <React.Fragment key={index}>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3">
                  <div className=''>
                    <CustomViewLabel label={`Currency Type`} value={selectedValue?.pO_CurrencyType} />
                  </div>
                  <div className="">
                    <CustomViewLabel label={"PO Amount"} value={item.poAmount} />
                  </div>
                  <div className="">
                    <CustomViewLabel label={"Pi Number"} value={item.piNumber} />
                  </div>
                  <div className="">
                    <CustomViewLabel label={"Pi Issued Date"} value={""} />
                  </div>
                  <div className=''>
                    <CustomViewLabel label={`Remaining Amount`} value={"0"} />
                  </div>
                  <div className=''>
                    <CustomViewLabel label={`Payment Terms`} value={selectedValue?.pO_PaymentTerms} />
                  </div>
                  <div className="">
                    <CustomViewLabel label={`Received Date ${index + 1}`} value={formatDateTest(item.receivedDate)} />
                  </div>
                  <div className="">
                    <CustomViewLabel label={`LC Number ${index + 1}`} value={item.lcNumber} />
                  </div>
                  <div className="">
                    {item?.imageURL && !imageError[index] ? (
                      <CustomFileViewer fileURL={item.imageURL} fileName={item.originalFileName ?? ''} label={`LC Received Received Upload ${index + 1}`} />
                    ) : (
                      <>
                        <div className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center flex items-center justify-center">
                          No image
                        </div>
                      </>
                    )}
                  </div>
                </div>

              </React.Fragment>
            ))
          ) : (
            <div className="text-xs ">No Lc received details available.</div>
          )}
        </div>
        <div className="mt-4 grid grid-cols-3 gap-4">
          <CustomViewLabel label="Payment / Lc Received Closed" value={plR_IsPaymentOrLCClosed} />
          <CustomViewLabel label="Payment / Lc Received Close Date" value={formatDateTest1(selectedValue?.plR_PaymentOrLCClosedDate)} />
        </div>
      </div>
    </>
  )
}

