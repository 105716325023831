// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";
import SubAccodion from "../../general-components/SubAccodion";

export default function ViewPaymentLCReceived({ selectedValue }) {
    const plR_IsPaymentOrLCClosed = selectedValue?.plR_IsPaymentOrLCClosed ? 'True' : 'False';
    const [imageError, setImageError] = useState({});

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <SubAccodion title={"Direct Payment"}>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    {selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0 ? (
                        selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.map((item, index) => (
                            <React.Fragment key={index}>
                                <CustomViewLabel label={`${index + 1} - Currency Type`} value={item.currencyType} />
                                <CustomViewLabel label={`${index + 1} - PO Amount`} value={item.poAmount} />
                                <CustomViewLabel label={`${index + 1} - Pi Number`} value={item.piNumber} />
                                <CustomViewLabel label={`${index + 1} - Pi Issued Date`} value={""} />
                                <CustomViewLabel label={`${index + 1} - Total Received Amount`} value={item.totalReceivedAmount} />
                                <CustomViewLabel label={`${index + 1} - Remaining Amount`} value={item.remainingAmount} />
                                <CustomViewLabel label={`${index + 1} - Payment Terms`} value={item.paymentTerms} />
                                {/* <CustomViewLabel label={`${index + 1} - Invoice Generated Date`} value={formatDateTest1(item.invoiceGenerateDate)} /> */}
                                {/* <CustomViewLabel label={`${index + 1} - Invoice Number`} value={item.invoiceNumber} /> */}
                                <CustomViewLabel label={`${index + 1} - Payment Received Date`} value={formatDateTest1(item.paymentReceivedDate)} />
                                <CustomViewLabel label={`${index + 1} - Payment Type`} value={item.paymentReceived} />
                                <CustomViewLabel label={`${index + 1} - Payment Received`} value={item.amount} />
                                <CustomViewLabel label={`${index + 1} - Bank Reference Number`} value={item.bankReferenceNumber} />

                                {item.purchaseOrderPaymentReceivedImagesList && item.purchaseOrderPaymentReceivedImagesList.length > 0 ? (
                                    <div>
                                        {item.purchaseOrderPaymentReceivedImagesList.map((image, imgIndex) => (
                                            <div key={imgIndex}>
                                                <CustomFileViewer
                                                    fileURL={image.imageURL}
                                                    fileName={image.imageOriginalFileName ?? ''}
                                                    label={`Attachment ${imgIndex + 1}`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-xs">No attachments available.</p>
                                )
                                }

                            </React.Fragment>
                        ))
                    ) : (
                        <div className="text-xs ">No Direct payment details available.</div>
                    )}
                </div>
            </SubAccodion>

            <SubAccodion title={"LC"}            >
                <div className=" max-w-full w-[1000px]">
                    {selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0 ? (
                        selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3">
                                    <div className=''>
                                        <CustomViewLabel label={`Currency Type`} value={selectedValue?.pO_CurrencyType} />
                                    </div>
                                    <div className="">
                                        <CustomViewLabel label={"PO Amount"} value={item.poAmount} />
                                    </div>
                                    <div className="">
                                        <CustomViewLabel label={"Pi Number"} value={item.piNumber} />
                                    </div>
                                    <div className="">
                                        <CustomViewLabel label={"Pi Issued Date"} value={""} />
                                    </div>
                                    <div className=''>
                                        <CustomViewLabel label={`Remaining Amount`} value={"0"} />
                                    </div>
                                    <div className=''>
                                        <CustomViewLabel label={`Payment Terms`} value={selectedValue?.pO_PaymentTerms} />
                                    </div>
                                    <div className="">
                                        <CustomViewLabel label={`Received Date ${index + 1}`} value={formatDateTest(item.receivedDate)} />
                                    </div>
                                    <div className="">
                                        <CustomViewLabel label={`LC Number ${index + 1}`} value={item.lcNumber} />
                                    </div>
                                    <div className="">
                                        {item?.imageURL && !imageError[index] ? (
                                            <CustomFileViewer fileURL={item.imageURL} fileName={item.originalFileName ?? ''} label={`LC Received Received Upload ${index + 1}`} />
                                        ) : (
                                            <>
                                                <div className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center flex items-center justify-center">
                                                    No image
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                            </React.Fragment>
                        ))
                    ) : (
                        <div className="text-xs ">No Lc details available.</div>
                    )}
                </div>
            </SubAccodion>

            <div className="mt-4 grid grid-cols-3 gap-4">
                <CustomViewLabel label="Payment / Lc Received Closed" value={plR_IsPaymentOrLCClosed} />
                <CustomViewLabel label="Payment / Lc Received Close Date" value={formatDateTest1(selectedValue?.plR_PaymentOrLCClosedDate)} />
            </div>
        </>
    )
}
