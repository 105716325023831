import React from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomFileViewer from '../../general-components/CustomFileViewer.component'

export default function PaymentReceivedAttachmentImage({ onCancel, selectedImages }) {
    return (
        <>
            <div className="pb-5  bg-white rounded-lg max-w-full">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[400]">Payment Received Attachment View</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-2 my-4">
                    <div className='max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[500px] border border-prp p-4 rounded-lg'>
                        {selectedImages && selectedImages.length > 0 ? (
                            selectedImages.map((image, index) => (
                                <div key={index} className="border rounded p-1">
                                    <CustomFileViewer fileURL={image.imageURL} fileName={image.imageOriginalFileName ?? ''} label={`Attachment ${index + 1}`} />
                                </div>
                            ))
                        ) : (
                            <p>No attachments available.</p>
                        )}
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    )
}
