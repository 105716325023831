// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import EditFinalAmountToPay from "./EditFinalAmountToPay";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import ViewInvoiceGenerated from "../ManageInvoiceGenerated/ViewInvoiceGenerated";
import ViewBiDraftIssued from "../ManageBiDraftIssued/ViewBiDraftIssued";
import ViewFinalBiIssued from "../ManageFinalBiIssued/ViewFinalBiIssued";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";
import SubAccodion from "../../general-components/SubAccodion";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import PopUp from "../../general-components/PopUp.componenet";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomInputNumberDecimal from "../../general-components/CustomInputNumberDecimal.component";

export default function ManageFinalAmountToPayEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([11]);
    const [updatePopup, setUpdatePopup] = useState(false);

    const [orderDate, setOrderData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checkboxClose, setCheckboxClose] = useState(false)
    const [finalAmt, setFinalAmt] = useState("");
    const plR_IsPaymentOrLCClosed = selectedValue?.plR_IsPaymentOrLCClosed ? 'True' : 'False';
    const [imageError, setImageError] = useState({});
    const [remainAmt, setRemainAmt] = useState("");
    const [endCloseDateTime, setEndCloseDateTime] = useState(null);

    const endFormatDate = (date) =>
        date
            ? new Date(date).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // 12-hour format
            })
            : 'N/A';

    const handleCheckboxChange = (state) => {
        setCheckboxClose(state);

        // Set the time every time the checkbox is flagged (true)
        if (state) {
            const timestamp = new Date().toISOString();
            setEndCloseDateTime(timestamp); // update time on each flag
        }
    };

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setOrderData(currentDate);
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setCheckboxClose(selectedValue?.faP_IsFinalAmountToPayClose);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (selectedValue) {
            console.log("Selected Value:", selectedValue);
            // setCheckboxClose(selectedValue?.faP_IsFinalAmountToPayClose);

            const paymentDetails = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail || [];
            console.log("Payment Details:", paymentDetails);

            // Get the last row's `remainingAmount` based on the highest `id`
            const lastRowRemainingAmount = paymentDetails.reduce((lastRow, currentRow) => {
                return currentRow.id > (lastRow?.id || 0) ? currentRow : lastRow;
            }, null)?.remainingAmount;

            console.log("Last Row Remaining Amount:", lastRowRemainingAmount);
            setFinalAmt(lastRowRemainingAmount || 0); // Default to 0 if no rows are found
        }
    }, [selectedValue]);

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setOrderData(formattedDate);
    }, []);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftComment: "",
                faP_FinalAmountToPayClosedDate: orderDate || null,
                faP_FinalAmountToPay: finalAmt || 0,
                faP_IsFinalAmountToPayClose: checkboxClose || false
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const hasPaymentDetails = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0;
    const hasLCDetails = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0;

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Final Amount To Pay</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex.includes(2)}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Direct Payment / LC"}
                        isOpen={openAccordionIndex.includes(3)}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex.includes(4)}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex.includes(5)}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex.includes(6)}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex.includes(7)}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex.includes(8)}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <ViewInvoiceGenerated
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"BL Draft Issued"}
                        isOpen={openAccordionIndex.includes(9)}
                        onClick={() => handleAccordionClick(9)}
                    >
                        <ViewBiDraftIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Final BL Issued"}
                        isOpen={openAccordionIndex.includes(10)}
                        onClick={() => handleAccordionClick(10)}
                    >
                        <ViewFinalBiIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Final Amount To Pay"}
                        isOpen={openAccordionIndex.includes(11)}
                        onClick={() => handleAccordionClick(11)}
                    >
                        {/* <EditFinalAmountToPay /> */}
                        <div>
                            {hasPaymentDetails && (
                                <SubAccodion title={"Direct Payment"}>
                                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                                        {selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0 ? (
                                            selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <CustomViewLabel label={`${index + 1} - Currency Type`} value={item.currencyType} />
                                                    <CustomViewLabel label={`${index + 1} - PO Amount`} value={item.poAmount} />
                                                    <CustomViewLabel label={`${index + 1} - Pi number`} value={item.piNumber} />
                                                    <CustomViewLabel label={`${index + 1} - Pi Issued Date`} value={""} />
                                                    <CustomViewLabel label={`${index + 1} - Total Received Amount`} value={item.totalReceivedAmount} />
                                                    <CustomViewLabel label={`${index + 1} - Remaining Amount`} value={item.remainingAmount} />
                                                    <CustomViewLabel label={`${index + 1} - Payment Terms`} value={item.paymentTerms} />
                                                    {/* <CustomViewLabel label={`${index + 1} - Invoice Generated Date`} value={formatDateTest1(item.invoiceGenerateDate)} /> */}
                                                    {/* <CustomViewLabel label={`${index + 1} - Invoice Number`} value={item.invoiceNumber} /> */}
                                                    <CustomViewLabel label={`${index + 1} - Payment Received Date`} value={formatDateTest1(item.paymentReceivedDate)} />
                                                    <CustomViewLabel label={`${index + 1} - Payment Type`} value={item.paymentReceived} />
                                                    <CustomViewLabel label={`${index + 1} - Payment Received`} value={item.amount} />
                                                    <CustomViewLabel label={`${index + 1} - Bank Reference Number`} value={item.bankReferenceNumber} />
                                                    {/* <CustomViewLabel label={`${index + 1} - Attachment`} value={""} /> */}

                                                    {item.purchaseOrderPaymentReceivedImagesList && item.purchaseOrderPaymentReceivedImagesList.length > 0 ? (
                                                        <div>
                                                            {item.purchaseOrderPaymentReceivedImagesList.map((image, imgIndex) => (
                                                                <div key={imgIndex}>
                                                                    <CustomFileViewer
                                                                        fileURL={image.imageURL}
                                                                        fileName={image.imageOriginalFileName ?? ''}
                                                                        label={`Attachment ${imgIndex + 1}`}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <p className="text-xs">No attachments available.</p>
                                                    )
                                                    }
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="text-xs ">No Direct payment details available.</div>
                                        )}
                                    </div>
                                </SubAccodion>
                            )}

                            {!hasPaymentDetails && hasLCDetails && (
                                <SubAccodion title={"LC"}>
                                    <div className=" max-w-full w-[1000px]">
                                        {selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0 ? (
                                            selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3">
                                                        <div className=''>
                                                            <CustomViewLabel label={`Currency Type`} value={selectedValue?.pO_CurrencyType} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={"PO Amount"} value={item.poAmount} />
                                                        </div>
                                                                                                              <div className="">
                                                            <CustomViewLabel label={"Pi Number"} value={item.piNumber} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={"Pi Issued Date"} value={""} />
                                                        </div>
                                                        <div className=''>
                                                            <CustomViewLabel label={`Remaining Amount`} value={"0"} />
                                                        </div>
                                                        <div className=''>
                                                            <CustomViewLabel label={`Payment Terms`} value={selectedValue?.pO_PaymentTerms} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={`Received Date ${index + 1}`} value={formatDateTest(item.receivedDate)} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={`LC Number ${index + 1}`} value={item.lcNumber} />
                                                        </div>
                                                        <div className="">
                                                            {item?.imageURL && !imageError[index] ? (
                                                                <CustomFileViewer fileURL={item.imageURL} fileName={item.originalFileName ?? ''} label={`LC Received Received Upload ${index + 1}`} />
                                                            ) : (
                                                                <>
                                                                    <div className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center flex items-center justify-center">
                                                                        No image
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="text-xs ">No Lc details available.</div>
                                        )}
                                    </div>
                                </SubAccodion>
                            )}
                        </div>

                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400]">
                                        Final Amount To Pay Date
                                    </label>
                                    <input
                                        type="datetime-local"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderData(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <CustomInputNumberDecimal label="Amount Due" placeholder={"Enter"} value={finalAmt} onChange={setFinalAmt} isRequired={false} />
                            </div>
                            {/* <div>
                                <CustomInputNumber label="Remaining Amount" value={remainAmt} onChange={setRemainAmt} placeholder={"Enter"} isRequired={false} />
                            </div> */}
                            <div className="">
                                <CustomCheckBox2
                                    label={"Final Amount To Pay"}
                                    state={checkboxClose}
                                    setState={handleCheckboxChange}
                                ></CustomCheckBox2>
                            </div>
                            {checkboxClose && (
                                <CustomViewLabel
                                    label="Final Amount To Pay Date & Time"
                                    value={endFormatDate(endCloseDateTime)}
                                />
                            )}
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}
        </>
    )
}
