// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// ICONSbg-white
import Title from "../../components/general-components/Title.component";
import CustomButton1 from "../../components/general-components/CustomButton1.component";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect1 from "../../components/general-components/CustomSelect1.component";

import ManagePiTrackingReportEdit from "../../components/forms-components/ManagePiTrackingReport/ManagePiTrackingReportEdit";
import CustomSelectMulti from './../../components/general-components/CustomSelectMulti.component';
import Pagination from "../../components/general-components/Pagination.component";
import { Tooltip } from "@mui/material";

export default function ManagePiTrackingReport() {
    let [popupEdit, setPopupEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [paperTData, setPaperTData] = useState([]);
    const [selectedPaperTId, setSelectedPaperTId] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };
    const [inputData, setInputData] = useState([]);

    const getCurrentISTDate = () => {
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000)
        const formattedDate = istDate.toISOString().slice(0, 16);
        return formattedDate; // 'yyyy-MM-ddTHH:mm'
    };

    // Function to fetch data
    const fetchApiData = async () => {
        setIsLoading(true);
        try {
            const requestPayload = {
                paperTypeId: selectedPaperTId && selectedPaperTId.value ? String(selectedPaperTId.value) : "", // check if paperTId exists
                pageNo: 0,
                pageSize: 0
            };
    
            // Only include fromDate and toDate when they are set
            if (fromDate && toDate && fromDate !== getCurrentISTDate() && toDate !== getCurrentISTDate()) {
                requestPayload.fromDate = fromDate;
                requestPayload.toDate = toDate;
            }
    
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageMIS/GetMIS_PITrackingList`,
                requestPayload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setInputData(response.data.data);
                setCurrentPage(1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };   

    // Handle search click
    const handleSearch = () => {
        fetchApiData(); // Fetch data with the latest fromDate and toDate if they are set
    };

    // Handle reset click (reset dates to current date)
    // Handle reset click (reset dates to current date and paper type)
    const handleReset = () => {
        const currentDate = getCurrentISTDate();
        setFromDate(currentDate);
        setToDate(currentDate);
        setSelectedPaperTId("");  // Reset paper type selection
        fetchApiData(); // Fetch data with default current date and no paper type
    };

    // Function to fetch paper type data
    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaperTypeList`, setPaperTData, "Error fetching Paper Type Data");
    }, []);

    useEffect(() => {
        fetchApiData(); // Fetch all records initially
    }, [rowsPerPage]);

    // Paginated data for table rendering
    const paginatedData = inputData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    // Effect to update total pages when the input data changes
    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    // Handle paper type selection
    const handlePaperTChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedPaperTId(selectedOption);
        } else {
            setSelectedPaperTId("");
        }
    }

    const paperTypeOptions = [
        ...paperTData.map(cData => ({
            value: cData.id,
            label: cData.paperType
        })),
        { value: '', label: 'Both' }  // New "both" option
    ];

    const handleExport = async () => {
        setIsLoading(true);
        try {
            const requestPayload = {
                paperTypeId: selectedPaperTId && selectedPaperTId.value ? String(selectedPaperTId.value) : "", // check if paperTId exists
                pageNo: 0,
                pageSize: 0
            };
    
            // Only include fromDate and toDate when they are set
            if (fromDate && toDate && fromDate !== getCurrentISTDate() && toDate !== getCurrentISTDate()) {
                requestPayload.fromDate = fromDate;
                requestPayload.toDate = toDate;
            }
    
            console.log("Request Payload:", requestPayload);
    
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageMIS/ExportMIS_PITrackingData`,
                requestPayload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
    
            console.log("Response:", response);
    
            const result = response.data;
    
            if (response.status === 200 && result.isSuccess && result.data) {
                console.log("Decoded Data:", result.data);
                const byteCharacters = atob(result.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `MIS PI Tracking Report.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                console.log(`Template downloaded successfully.`);
            } else {
                console.error(`Failed to download template. Response:`, result);
                toast.error("Failed to download template.");
            }
        } catch (error) {
            console.error("Error during export:", error);
            toast.error("An error occurred while exporting data.");
        } finally {
            setIsLoading(false);
        }
    };   

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const isEmpty = (value) => {
        return value === "" || value === "-";
    };

    // Modify the row rendering logic to conditionally apply a class
    const getRowClass = (row) => {
        // Check if any of the cells contain invalid data
        const invalidCell = Object.values(row).some((cell) => isEmpty(cell));
        return invalidCell ? "highlight-row" : "";
    };

    React.useEffect(() => {
        const rows = document.querySelectorAll(".custom-table tbody tr"); // Only target rows in <tbody>
        rows.forEach(row => {
            const cells = Array.from(row.cells); // Convert HTMLCollection to Array
            const hasInvalidCell = cells.some(cell => {
                const text = cell.innerText.trim();
                const hasButton = cell.querySelector("button"); // Check if cell contains a button
                return (!hasButton && (text === "" || text === "-")); // Only highlight if no button and invalid text
            });
            if (hasInvalidCell) {
                row.classList.add("highlight-row"); // Add the highlight class
            } else {
                row.classList.remove("highlight-row"); // Remove the highlight class if valid
            }
        });
    }, [inputData]);  // Re-run the effect whenever inputData changes

    return (
        <>
            <div className="flex flex-col h-full p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"MIS PI Tracking"} title2={"Manage Report"} />

                    <div className=" bg-white rounded grow">
                        <div className="px-2 lg:px-4">
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 mt-5 minbox">
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="date" className="text-xs font-[400]">From Date</label>
                                        <input
                                            type="date"
                                            required
                                            className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                            value={fromDate || ""}
                                            onChange={(e) => setFromDate(e.target.value || "")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="date" className="text-xs font-[400]">To Date</label>
                                        <input
                                            type="date"
                                            required
                                            className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                            value={toDate || ""}
                                            onChange={(e) => setToDate(e.target.value || "")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <CustomSelect1 label={"Paper Type"}
                                        isRequired={false}
                                        options={paperTypeOptions}
                                        onChange={handlePaperTChange}
                                    />
                                </div>
                                <div onClick={handleSearch}>
                                    <Tooltip title="SEARCH" arrow placement="bottom">
                                        <span>
                                            <CustomButton1 label={"Search"} className="text-white bg-prp-color w-full mt-5 md:mt-0" type="button" />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div onClick={handleReset}>
                                    <Tooltip title="RESET" arrow placement="bottom">
                                        <span>
                                            <CustomButton1 label={"Reset"} className="text-white bg-prp-color w-full" type="button" />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div onClick={handleExport}>
                                    <Tooltip title="EXPORT" arrow placement="bottom">
                                        <span>
                                            <CustomButton1 label={"Export"} className="text-white bg-prp-color w-full" type="button" />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                <table className="w-full custom-table" border={1}>
                                    <thead>
                                        <tr className="table-heading">
                                            <td className="">S.No</td>
                                            <td>PI NO.</td>
                                            <td>CONSIGNEE/NOTIFY PARTY </td>
                                            <td>PORT OF DISCHARGE </td>
                                            <td>Shipment Schedule</td>
                                            <td>PAYMENT TERM </td>
                                            <td>BRAND </td>
                                            <td>PACKING  </td>
                                            <td>Paper Type</td>
                                            <td>QTY.</td>
                                            <td>COMMISSION </td>
                                            <td>Created Date </td>
                                            <td>Created By </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td colSpan="21" className="text-start">Loading...</td>
                                            </tr>
                                        ) : (paginatedData.length === 0 ? (
                                            <tr>
                                                <td colSpan="21" className="text-start">No data found</td>
                                            </tr>
                                        ) : (
                                            paginatedData.map((row, index) => (
                                                <tr key={index} className={getRowClass(row)}>
                                                    <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                    <td>{row.piNumber}</td>
                                                    <td>{row.customerName}</td>
                                                    <td>{row.portDischarge}</td>
                                                    <td>{formatDateTest1(row.shipmentSchedule)}</td>
                                                    <td>{row.paymentTerms}</td>
                                                    <td>{row.brand}</td>
                                                    <td>{row.typeOfPackaging}</td>
                                                    <td>{row.paperType}</td>
                                                    <td>{row.pO_Quantity}</td>
                                                    <td>{row.pO_CommissionPerTon}</td>
                                                    <td>{new Date(row.createdDate).toLocaleDateString('en-GB')}</td>
                                                    <td>{row.createdBy}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                currentPage={currentPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                data={inputData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
