import React from 'react'

export default function Pagination({
    currentPage,
    rowsPerPage,
    setRowsPerPage,
    totalPages,
    setCurrentPage,
    data
}) {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisibleButtons = 5; // Number of buttons to display at a time
    
        // Logic to calculate the range of page numbers to show
        let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);
    
        // Adjust if the range is less than maxVisibleButtons
        if (endPage - startPage + 1 < maxVisibleButtons) {
            startPage = Math.max(1, endPage - maxVisibleButtons + 1);
        }
    
        // Add the first page and ellipsis if needed
        if (startPage > 1) {
            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handlePageChange(1)}
                    className={`mx-1 px-3 py-1 rounded-full ${currentPage === 1 ? 'bg-gray-200' : 'bg-gray-100'}`}
                >
                    1
                </button>
            );
            if (startPage > 2) {
                pageNumbers.push(
                    <span key="start-ellipsis" className="mx-1 px-2">
                        ...
                    </span>
                );
            }
        }
    
        // Add the range of visible page numbers
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`mx-1 px-3 py-1 rounded-full ${i === currentPage ? 'bg-gray-200' : 'bg-gray-100'}`}
                >
                    {i}
                </button>
            );
        }
    
        // Add the last page and ellipsis if needed
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push(
                    <span key="end-ellipsis" className="mx-1 px-2">
                        ...
                    </span>
                );
            }
            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageChange(totalPages)}
                    className={`mx-1 px-3 py-1 rounded-full ${currentPage === totalPages ? 'bg-gray-200' : 'bg-gray-100'}`}
                >
                    {totalPages}
                </button>
            );
        }
    
        return pageNumbers;
    };
    

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="flex items-center justify-between mt-5">
                <div className="text-sm text-black font-normal">
                    Showing {(currentPage - 1) * rowsPerPage + 1} to {Math.min(currentPage * rowsPerPage, data.length)} of {data.length} entries
                </div>


                <div className="flex gap-2">
                    <div className="relative">
                        <select className="h-full block appearance-none w-full bg-white border rounded-md border-gray-400 text-gray-700  py-2 px-4 pr-8 leading-tight focus:outline-none "
                            onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
                            value={rowsPerPage}
                        >

                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button
                            className={`px-2 py-1 mx-1  ${currentPage === 1 ? 'text-gray-200' : ' text-black'}`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            &lt;
                        </button>
                        {renderPageNumbers()}
                        <button
                            className={`px-2 py-1 mx-1  ${currentPage === totalPages ? 'text-gray-200' : ' text-black'}`}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}


