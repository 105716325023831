import React, { useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomInputNumberDecimal from './../../general-components/CustomInputNumberDecimal.component';

const options = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },
    { value: "5", label: "Option 5" },
    { value: "6", label: "Option 6" },
    { value: "7", label: "Option 7" },
    { value: "8", label: "Option 8" },
    { value: "9", label: "Option 9" },
    { value: "10", label: "Option 10" },
];

export default function ManageAdvanceAmountEdit({ onCancel }) {
    const [test2, setTest2] = useState("");
    const [test3, setTest3] = useState("");
    const [test4, setTest4] = useState("");
    const [test5, setTest5] = useState("");
    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Advance Amount</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[700px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-2 ">
                        {/* <CustomSelect1 label="PAYMENT TERM" options={options} /> */}

                        <CustomTextField2 label="BANK" placeholder="Enter" isRequired={false}
                            value={test3}
                            onChange={setTest3}
                        />
                        {/* <CustomInputNumber label="PROFORMA AMOUNT" placeholder="Enter" isRequired={false}
                            value={test2}
                            onChange={setTest2}
                        />
                        <CustomInputNumber label="ADVANCE RECEIVED" placeholder="Enter" isRequired={false}
                            value={test4}
                            onChange={setTest4}
                        /> */}
                        <CustomInputNumberDecimal label="Bank Commission" placeholder="Enter" isRequired={false}
                            value={test5}
                            onChange={setTest5}
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={onCancel}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>
        </>
    )
}

