import { BsCheck } from "react-icons/bs";

function CustomCheckBox4(props) {
    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="id" className="text-xs font-400">
                {props.label.toUpperCase()}
            </label>
            <div
                className={`${
                    props.state ? " bg-prp-color " : " bg-white "
                } border flex justify-center items-center rounded h-[34px] w-[34px]`}
                onClick={() => {
                    if (!props.disabled && !props.readonly) { // Disable toggle if readonly
                        props.setState(!props.state);
                    }
                }}
                style={{
                    cursor: props.disabled || props.readonly ? 'not-allowed' : 'pointer', // Change cursor for readonly
                }}
            >
                {props.state && <BsCheck className="text-2xl text-white" />}
            </div>
        </div>
    );
}

export default CustomCheckBox4;
