import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewBiDraftIssued({ selectedValue }) {
    const biD_IsBIDraftIssueClose = selectedValue?.biD_IsBIDraftIssueClose ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const biD_BIDraftIssueClosedDate = formatDateTest(selectedValue?.biD_BIDraftIssueClosedDate);

    return (
        <>
            <div>
                {/* Display remarks log */}
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <div>
                        <div className="flex flex-col">
                            <label htmlFor="id" className="text-xs font-[400]">
                                BL Draft Issued Remarks
                            </label>
                            {selectedValue?.biDraftIssuedRemarkLogList?.map((remark, index) => (
                                <React.Fragment key={index}>
                                    <input
                                        type="text"
                                        disabled
                                        value={remark?.remarks}
                                        className="p-2 min-w-[14rem] bg-white uppercase text-gray-400 text-xs"
                                    />
                                    <p className="text-xs text-gray-500">
                                        By: {remark?.creatorName} | Date: {formatDateTest1(remark?.createdDate)}
                                    </p>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    {/* Display comments log */}
                    <div>
                        <div className="flex flex-col">
                            <label htmlFor="id" className="text-xs font-[400]">
                                BL Draft Issued Comments
                            </label>
                            {selectedValue?.biDraftIssuedCommentLogList?.map((comment, index) => (
                                <React.Fragment key={index}>
                                    <input
                                        type="text"
                                        disabled
                                        value={comment?.comments}
                                        className="p-2 min-w-[14rem] bg-white uppercase text-gray-400 text-xs"
                                    />
                                    <p className="text-xs text-gray-500">
                                        By: {comment?.creatorName} | Date: {formatDateTest1(comment?.createdDate)}
                                    </p>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    {/* Display uploaded images */}
                    <div>
                        {selectedValue?.biDraftIssuedImagesList?.map((image, index) => (
                            <React.Fragment key={index}>
                                <CustomFileViewer
                                    fileURL={image.imageURL}
                                    fileName={image.originalFileName ?? ''}
                                    label={`BL Draft Issued Upload`}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                {/* Display metadata */}
                <div className="max-w-full grid grid-cols-2 mt-4 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="BL Draft Issued Closed Date" value={formatDateTest1(selectedValue?.biD_BIDraftIssueClosedDate)} />
                    <CustomViewLabel label="BL Draft Issued Closed" value={biD_IsBIDraftIssueClose} />
                    <CustomViewLabel label="BL Draft Issued Close Date & Time" value={formatDateTest1(selectedValue?.biD_BIDraftIssueClosedDateTime)} />
                </div>
            </div>
        </>
    );
}
