// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewOrderUnderProcess({ selectedValue }) {
    const ouP_OrderUnderProcessDate = selectedValue?.ouP_OrderUnderProcessDate ? new Date(selectedValue.ouP_OrderUnderProcessDate).toLocaleDateString('en-GB') : 'N/A';

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';
    
        const date = new Date(dateString);
        const options = { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="Shipment Schedule" value={formatDateTest1(selectedValue?.ouP_OrderUnderProcessDate)} />
                    <CustomViewLabel label="Order Under Process Close" value={selectedValue?.ouP_IsOrderUnderProcess} />
                    <CustomViewLabel label="Order Under Process Close Date" value={formatDateTest1(selectedValue?.ouP_OrderUnderProcessClosedDate)} />
                </div>
            </div>
        </>
    )
}
