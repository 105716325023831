import React, { useState, useEffect } from "react";
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer } from "../components/general-components/Tab.component";

import PendingPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/PendingPIConfirmedbyCustomer";
import AcceptedPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/AcceptedPIConfirmedbyCustomer";
import RejectedPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/RejectedPIConfirmedbyCustomer";
import AllPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/AllPIConfirmedbyCustomer";

import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";

export default function ManagePIConfirmedbyCustomer() {
    const [activeTab, setActiveTab] = useState(0);
    const { user } = useAuth();
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true });

    // Cache for tabs
    const [tabs, setTabs] = useState({
        pending: <PendingPIConfirmedbyCustomer Permission={Permission} />,
        approved: <AcceptedPIConfirmedbyCustomer Permission={Permission} />,
        rejected: <RejectedPIConfirmedbyCustomer Permission={Permission} />,
        all: <AllPIConfirmedbyCustomer Permission={Permission} />,
    });

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE TRACKING > MANAGE PI CONFIRMATION"));
        }
    }, [user]);

    return (
        <div className="flex flex-col h-full uppercase">
            <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                <Title title1={"Manage PI Confirmation"} title2={"Manage Tracking"} />

                <div className="px-2 lg:px-4 minbox mt-5">
                    <TabContainer showArrow={true}>
                        <Tab label="Pending PI" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Approved PI" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Rejected PI" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="All PI" index={3} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>

                {/* Render the cached tabs based on activeTab */}
                <div>
                    {activeTab === 0 && tabs.pending}
                    {activeTab === 1 && tabs.approved}
                    {activeTab === 2 && tabs.rejected}
                    {activeTab === 3 && tabs.all}
                </div>
            </div>
        </div>
    );
}
