// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import Accordion from "../../general-components/Accodion";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import ViewInvoiceGenerated from "../ManageInvoiceGenerated/ViewInvoiceGenerated";
import ViewBiDraftIssued from "../ManageBiDraftIssued/ViewBiDraftIssued";
import ViewFinalBiIssued from "../ManageFinalBiIssued/ViewFinalBiIssued";
import ViewFinalAmountToPay from "../ManageFinalAmountToPay/ViewFinalAmountToPay";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ViewPaymentReceived from "./ViewPaymentReceived";

export default function ManagePaymentReceivedView({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([12]);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">View Final Payment Received</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion
                        title={"PO received"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion
                        title={"PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion
                        title={"PI Confirmation"}
                        isOpen={openAccordionIndex.includes(2)}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Direct Payment / LC"}
                        isOpen={openAccordionIndex.includes(3)}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex.includes(4)}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex.includes(5)}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex.includes(6)}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex.includes(7)}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex.includes(8)}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <ViewInvoiceGenerated
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"BL Draft Issued"}
                        isOpen={openAccordionIndex.includes(9)}
                        onClick={() => handleAccordionClick(9)}
                    >
                        <ViewBiDraftIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Final BL Issued"}
                        isOpen={openAccordionIndex.includes(10)}
                        onClick={() => handleAccordionClick(10)}
                    >
                        <ViewFinalBiIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Final Amount To Pay"}
                        isOpen={openAccordionIndex.includes(11)}
                        onClick={() => handleAccordionClick(11)}
                    >
                        <ViewFinalAmountToPay
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Final Payment Received"}
                        isOpen={openAccordionIndex.includes(12)}
                        onClick={() => handleAccordionClick(12)}
                    >
                        <ViewPaymentReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                </div>
                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    );
}

