import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "./pages/Login.page";
import Admin from "./pages/Admin.page";
import NotFound from "./pages/NotFound.page";
import { AuthProvider, ProtectRoute, useAuth } from "./pages/context";
import Dashboard from "./pages/sections/Dashboard.section";
import ManageProfile from "./pages/sections/ManageProfile.section";
import ManageAttributes from "./pages/sections/ManageAttributes.section";
import ManageCompanyDetails from "./pages/sections/ManageCompanyDetail.section";
import ManageTypeOfPackaging from "./pages/sections/ManageTypeOfPackaging.section";
import ManageUnit from "./pages/sections/ManageUnit.section";
import RolePermission from "./pages/sections/RolePermission";
import ManageGender from "./pages/sections/ManageGender.section";
import ManagePackagingType from "./pages/sections/ManagePackagingType.section";
import ManageContainerType from "./pages/sections/ManageContainerType.section";
import ManageDeliveryTerms from "./pages/sections/ManageDeliveryTerms.section";
import ManageProductionStatus from "./pages/sections/ManageProductionStatus.section";
import ManageBRC from "./pages/sections/ManageBRC.section";
import VisitorCompany from "./pages/sections/VisitorCompany.section";
import ManagePaymentTerms from "./pages/sections/ManagePaymentTerms.section";
import VisitorDetails from "./pages/sections/VisitorDetails.section";
import ManageForwarding from "./pages/sections/ManageForwarding.section";
import ManageTerritory from "./pages/sections/ManageTerritory.section";
import ManageBranchDetails from "./pages/sections/ManageBranchDetails";

// import & export project
import ManageCustomerType from "./pages/sections/ManageCustomerType";
import ManagePortDischarge from "./pages/sections/ManagePortDischarge.section";
import ManagePaperType from "./pages/sections/ManagePaperType.section";
import Leads from "./pages/sections/Leads.section";
import ManageCustomers from "./pages/sections/ManageCustomers.section";
import ManageCurrencyType from "./pages/sections/ManageCurrencyType.section";
import ManageTrackingStatus from "./pages/sections/ManageTrackingStatus.section";
import ManagePO from "./pages/sections/ManagePO.section";
import ManagePOIssued from "./pages/sections/ManagePOIssued.section";
import ManagePIConfirmedbyCustomer from "./pages/sections/ManagePIConfirmedbyCustomer.section";
import ManagePaymentReceived_LCReceived from "./pages/sections/ManagePaymentReceived_LCReceived.section";
import ManagePINumber from "./pages/sections/ManagePINumber";
import TrackingStatus from "./pages/sections/TrackingStatus.section";
import ManageOrderAccepted from "./pages/sections/ManageOrderAccepted.section";
import ManageOrderUnderProcess from "./pages/sections/ManageOrderUnderProcess.section";
import ManageBookingIssue from "./pages/sections/ManageBookingIssue.section";
import ManageContainersUnderLoading from "./pages/sections/ManageContainersUnderLoading.section";
import ManageInvoiceGenerated from "./pages/sections/ManageInvoiceGenerated.section";
import ManageBiDraftIssued from "./pages/sections/ManageBiDraftIssued.section";
import ManageBiIssued from "./pages/sections/ManageBiIssued.section";
import ManageFinalAmountToPay from "./pages/sections/ManageFinalAmountToPay.section";
import ManageDocumentSendDhlSubmitted from "./pages/sections/ManageDocumentSendDhlSubmitted.section";
import ManageAwbGeneratedPending from "./pages/sections/ManageAwbGeneratedPending.section";
import ManageOrderCompletedPending from "./pages/sections/ManageOrderCompletedPending.section";
import ManageTrackingStatusCustomer from "./pages/sections/ManageTrackingStatusCustomer.section";
import ManagePOAdd from "./pages/components/forms-components/ManagePO/ManagePOAdd";
import ManagePOIssuedAdd from "./pages/components/forms-components/ManagePOIssued/ManagePOIssuedAdd";
import PaymentLCReceivedAdd from "./pages/components/forms-components/ManagePaymentReceived_LCReceived/PaymentLCReceivedAdd";
import ManagePOEdit from "./pages/components/forms-components/ManagePO/ManagePOEdit";
import ManageCompanyType from "./pages/sections/ManageCompanyType.section";
import ManageBloodGroup from "./pages/sections/ManageBloodGroup.section";
import ManageMaritalStatus from "./pages/sections/ManageMaritalStatus.section";
import ManagePaymentRecieved from "./pages/sections/ManagePaymentRecieved.section";
import ManageParentCustomers from "./pages/sections/ManageParentCustomers.section";
import RoleEmployeePermission from "./pages/sections/RoleEmployeePermission";
import ManagePaymentReceived from "./pages/sections/ManagePaymentReceived.section";
import ManageTrackingStatusDesign from "./pages/components/forms-components/ManageTrackingStatus/ManageTrackingStatusDesign";
import ManageTrackingStatusDesignCustomer from "./pages/components/forms-components/ManageTrackingStatus/ManageTrackingStatusDesignCustomer";
import ManagePIConfirmationTab from "./pages/sections/ManagePIConfirmationTab";
import ManageCommission from "./pages/sections/ManageCommission.section";
import ManagePiTrackingReport from "./pages/sections/Report/ManagePiTrackingReport.section";
import ManageAdvanceAmount from "./pages/sections/Report/ManageAdvanceAmount.section";
import ManageFinalInvoicePaymentRecord from "./pages/sections/Report/ManageFinalInvoicePaymentRecord.section";
import ManageSBillRecord from "./pages/sections/Report/ManageSBillRecord.section";
import ManageCommissionReport from "./pages/sections/Report/ManageCommissionReport.section";
import ManageSellRate from "./pages/sections/Report/ManageSellRate.section";

function App() {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    // const { logout } = useAuth()
    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("DIYAN_IE_authToken");
        if (token) {
            const decodedJwt = parseJwt(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                // logout()
                localStorage.removeItem("user");
                localStorage.removeItem("DIYAN_IE_username");
                localStorage.removeItem("DIYAN_IE_authToken");
                localStorage.removeItem("activeTabDiyan");
                localStorage.removeItem("diyanActiveTrackingStatusTab");
                localStorage.removeItem("diyanCustomerTracking");
                window.location.href = "/";
            }
        }
        const isAuthenticated = !!token;
        setCurrentUser(isAuthenticated);
        setLoading(false);
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <section className="app bg-white uppercase">
            <AuthProvider>
                <Routes>
                    {currentUser ? (
                        <>
                            <Route path="/" element={<Navigate to="admin/app/dashboard" />} />
                            <Route
                                path="admin"
                                element={
                                    <ProtectRoute>
                                        <Outlet />
                                    </ProtectRoute>
                                }
                            >
                                <Route path="admin-profile" element={<ManageProfile />} />
                                <Route
                                    path="admin-manage-territories/admin-attributes"
                                    element={<ManageAttributes />}
                                />
                                <Route
                                    path="admin-manage-territories/admin-territories"
                                    element={<ManageTerritory />}
                                />
                                <Route path="admin-gender" element={<ManageGender />} />
                                <Route path="admin-customer-type" element={<ManageCustomerType />} />
                                <Route path="admin-company-details" element={<ManageCompanyDetails />} />
                                <Route path="visitor-company" element={<VisitorCompany />} />
                                <Route path="admin-Branch-details" element={<ManageBranchDetails />} />
                                <Route path="admin-port-discharge" element={<ManagePortDischarge />} />
                                <Route path="admin-paper-type" element={<ManagePaperType />} />
                                <Route path="admin-type-of-packaging" element={<ManageTypeOfPackaging />} />
                                <Route path="admin-uom" element={<ManageUnit />} />
                                <Route path="admin-packaging-type" element={<ManagePackagingType />} />
                                <Route path="admin-container-type" element={<ManageContainerType />} />
                                <Route path="admin-currency-type" element={<ManageCurrencyType />} />
                                <Route path="admin-delivery-terms" element={<ManageDeliveryTerms />} />
                                <Route path="admin-payment-terms" element={<ManagePaymentTerms />} />
                                <Route path="admin-production-status" element={<ManageProductionStatus />} />
                                <Route path="admin-brc" element={<ManageBRC />} />
                                <Route path="admin-forwarding" element={<ManageForwarding />} />
                                <Route path="admin-tracking-status" element={<TrackingStatus />} />
                                <Route path="admin-company-type" element={<ManageCompanyType />} />
                                <Route path="admin-blood-group" element={<ManageBloodGroup />} />
                                <Route path="admin-marital-status" element={<ManageMaritalStatus />} />
                                <Route path="admin-payment-received" element={<ManagePaymentRecieved />} />
                                <Route path="role-permission" element={<RolePermission />} />
                                <Route path="role-employee-permission" element={<RoleEmployeePermission />} />
                                <Route path="app" element={<Outlet />}>
                                    <Route path="dashboard" element={<Dashboard />} />
                                    <Route path="visitor-details" element={<VisitorDetails />} />
                                    <Route path="leads" element={<Leads />} />
                                    <Route path="customers" element={<ManageCustomers />} />
                                    <Route path="manage-tracking-status" element={<ManageTrackingStatus />} />
                                    <Route path="customers/parent-customer" element={<ManageParentCustomers />} />
                                    <Route
                                        path="customers/customer-tracking-status"
                                        element={<ManageTrackingStatusCustomer />}
                                    />
                                    <Route
                                        path="manage-tracking-status/customer-tracking-status"
                                        element={<ManageTrackingStatusDesign />}
                                    />
                                    <Route
                                        path="customers/customer-tracking-status/customer-tracking-status-design"
                                        element={<ManageTrackingStatusDesignCustomer />}
                                    />
                                    <Route path="manage-po-received" element={<ManagePO />} />
                                    <Route path="manage-pi-issued" element={<ManagePIConfirmationTab />} />
                                    <Route path="manage-pi-confirmation" element={<ManagePIConfirmedbyCustomer />} />
                                    <Route
                                        path="direct-payment-lc"
                                        element={<ManagePaymentReceived_LCReceived />}
                                    />
                                    <Route path="manage-pi-number" element={<ManagePINumber />} />
                                    <Route path="manage-order-accepted" element={<ManageOrderAccepted />} />
                                    <Route path="manage-order-under-process" element={<ManageOrderUnderProcess />} />
                                    <Route path="manage-booking-issue" element={<ManageBookingIssue />} />
                                    <Route
                                        path="manage-containers-under-loading"
                                        element={<ManageContainersUnderLoading />}
                                    />
                                    <Route path="manage-invoice-generated" element={<ManageInvoiceGenerated />} />
                                    <Route path="manage-bl-draft-issued" element={<ManageBiDraftIssued />} />
                                    <Route path="manage-bl-issued" element={<ManageBiIssued />} />
                                    <Route path="manage-final-amount-to-pay" element={<ManageFinalAmountToPay />} />
                                    <Route path="manage-payment-received" element={<ManagePaymentReceived />} />
                                    <Route
                                        path="manage-document-send-dhl-submitted"
                                        element={<ManageDocumentSendDhlSubmitted />}
                                    />
                                    <Route
                                        path="manage-awb-generated-pending"
                                        element={<ManageAwbGeneratedPending />}
                                    />
                                    <Route
                                        path="manage-order-completed-pending"
                                        element={<ManageOrderCompletedPending />}
                                    />
                                    <Route path="manage-po-received-add" element={<ManagePOAdd />} />
                                    <Route path="manage-po-issued-add" element={<ManagePOIssuedAdd />} />
                                    <Route
                                        path="manage-direct-payment-lc"
                                        element={<PaymentLCReceivedAdd />}
                                    />
                                    <Route path="manage-po-received-edit" element={<ManagePOEdit />} />
                                    <Route path="manage-commission" element={<ManageCommission />} />

                                    <Route path="manage-pi-tracking-report" element={<ManagePiTrackingReport />} />
                                    <Route path="manage-advance-amount-report" element={<ManageAdvanceAmount />} />
                                    <Route path="manage-final-invocie-payment-record-report" element={<ManageFinalInvoicePaymentRecord />} />
                                    <Route path="manage-s-bill-record-report" element={<ManageSBillRecord />} />
                                    <Route path="manage-sell-rate-report" element={<ManageSellRate />} />
                                    <Route path="commission-report" element={<ManageCommissionReport />} />

                                </Route>
                            </Route>
                            <Route path="/login" element={<Login />} />
                            <Route path="/*" element={<NotFound />} />
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<Login />} />
                            <Route path="/*" element={<Navigate to="/" />} />
                        </>
                    )}
                </Routes>
            </AuthProvider>
        </section>
    );
}

export default App;
