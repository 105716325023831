import React, { useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';
import CustomDate from '../../general-components/CustomDate.component';

export default function ManageFinalInvoicePaymentRecordEdit({ onCancel }) {
    const [test1, setTest1] = useState("");
    const [test2, setTest2] = useState("");
    const [test3, setTest3] = useState("");
    const [test4, setTest4] = useState("");
    const [test5, setTest5] = useState("");
    const [test6, setTest6] = useState("");
    const [test7, setTest7] = useState("");
    const [test8, setTest8] = useState("");

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Final Invoice, Payment Records</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[700px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-2 ">
                        {/* <CustomTextField2 label="CONSIGNEE" placeholder="Enter" isRequired={false}
                            value={test3}
                            onChange={setTest3}
                        /> */}

                        {/* <CustomTextField2 label="NOTIFY PARTY" placeholder="Enter" isRequired={false}
                            value={test1}
                            onChange={setTest1}
                        /> */}

                        {/* <CustomTextField2 label="DESTINATION COUNTRY" placeholder="Enter" isRequired={false}
                            value={test7}
                            onChange={setTest7}
                        /> */}

                        {/* <CustomInputNumberDecimal label="FOB VALUE" placeholder="Enter" isRequired={false} 
                            value={test2}
                            onChange={setTest2}
                        /> */}
                        <CustomTextField2 label="FREIGHT" placeholder="Enter" isRequired={false}
                            value={test4}
                            onChange={setTest4}
                        />
                        {/* <CustomInputNumberDecimal label="REMITTED AMOUNT" placeholder="Enter" isRequired={false}
                            value={test5}
                            onChange={setTest5}
                        /> */}

                        <CustomInputNumber label="Port Code" placeholder="Enter" isRequired={false}
                            value={test8}
                            onChange={setTest8}
                        />

                        <CustomInputNumber label="SB No" placeholder="Enter" isRequired={false}
                            value={test6}
                            onChange={setTest6}
                        />
                        <CustomDate label="Date" placeholder="Enter" isRequired={false}
                            value={test5}
                            onChange={setTest5}
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={onCancel}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>
        </>
    )
}
