// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import '../../../assets/css/selectbar.css';
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";
import Modal from 'react-modal';
import Select from 'react-select';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomBrowse from "../../general-components/CustomBrowse.component"
import CustomInputNumber from '../../general-components/CustomInputNumber.component'
import Accordion from "../../general-components/Accodion";
import CustomDate from "../../general-components/CustomDate.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton4 from "../../general-components/CustomButton4.component";
import CustomMultitext from "../../general-components/CustomMultitext.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";


import { BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { MdCancel } from "react-icons/md";

import LCReceivedEditInEdit from "../LCReceived/LCReceivedEditInEdit";
import LCReceivedEditInView from "../LCReceived/LCReceivedEditInView";
import PaymentReceivedEditInEdit from "../PaymentReceived/PaymentReceivedEditInEdit";
import PaymentReceivedEditInView from "../PaymentReceived/PaymentReceivedEditInView";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect2 from "../../general-components/CustomSelect2.component";
import CustomCheckBox4 from "../../general-components/CustomCheckBox4.component";
import CustomImagePdfMultiple from "../../general-components/CustomImagePdfMultiple.component";
import PaymentReceivedAttachmentImage from "../PaymentReceived/PaymentReceivedAttachmentImage";
import CustomInputNumberDecimal from "../../general-components/CustomInputNumberDecimal.component";

export default function PaymentLCReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {
  const [checkboxPi, setCheckboxPi] = useState(false);
  const [checkbox, setCheckbox] = useState(false); // Add this for LC received state
  const [openAccordionIndex, setOpenAccordionIndex] = useState([3]);
  const [prlCloseAll, setPlrCloseAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [updatePopup1, setUpdatePopup1] = useState(false);
  const [payType, setPayType] = useState("");
  const [poAmt, setPoAmt] = useState("");

  const [endCloseDateTime, setEndCloseDateTime] = useState(null);

  const endFormatDate = (date) =>
    date
      ? new Date(date).toLocaleString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // 12-hour format
      })
      : 'N/A';

  const handleCheckboxChange = (state) => {
    setPlrCloseAll(state);

    // Set the time every time the checkbox is flagged (true)
    if (state) {
      const timestamp = new Date().toISOString();
      setEndCloseDateTime(timestamp); // update time on each flag
    }
  };

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index) // Close if already open
        : [...prevIndexes, index] // Add to open accordions
    );
  };

  // const handleCheckboxPiChange = () => {
  //   setCheckboxPi(!checkboxPi);
  //   if (!checkboxPi) setCheckbox(false); // Uncheck LC when Payment is checked
  // };

  // const handleCheckboxChange = () => {
  //   setCheckbox(!checkbox);
  //   if (!checkbox) setCheckboxPi(false); // Uncheck Payment when LC is checked
  // };

  useEffect(() => {
    if (selectedValue) {
      const hasPaymentReceived = selectedValue.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0;
      const hasLcReceived = selectedValue.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0;

      if (hasLcReceived) {
        setCheckboxPi(false);
        setCheckbox(true); // Set LC received as checked if LC has values
      } else if (hasPaymentReceived) {
        setCheckboxPi(true);
        setCheckbox(false); // Set Payment received as checked if only payment has values
      } else {
        setCheckboxPi(true); // Default to true if both are empty
        setCheckbox(false);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      setPlrCloseAll(selectedValue.plR_IsPaymentOrLCClosed);
      setPoAmt(selectedValue.pO_Amount);
      setPoAmt1(selectedValue.pO_Amount);
    }
  }, [selectedValue])

  useEffect(() => {
    if (selectedValue?.pO_PaymentType !== undefined) {
      setPayType(selectedValue.pO_PaymentType);
      setCheckboxPi(selectedValue.pO_PaymentType === 1);
      setCheckbox(selectedValue.pO_PaymentType === 2);
    }
  }, [selectedValue]);

  // const [checkbox, setCheckbox] = useState(false);
  // const [checkboxEditPi, setCheckboxEditPi] = useState(false);
  const [rows, setRows] = useState([]);
  const [date, setDate] = useState("");
  const [attach, setAttach] = useState("");
  const [entries, setEntries] = useState([]);
  const [recordView, setRecordView] = useState("");
  const [recordEdit, setRecordEdit] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [lcNumber, setLcNumber] = useState("");
  const [remainAmtLc, setRemainamtLc] = useState(0);
  const [errors, setErrors] = useState({ mainAttach: false, rowAttach: [] });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);  // To store the selected file URL
  const [fileType, setFileType] = useState('');  // To store the file type (image/pdf)
  const [fileName, setFileName] = useState('');  // To store the file name

  const [piOptions, setPiOptions] = useState([]);
  const [selectedPiNumber, setSelectedPiNumber] = useState(null);
  const [piIssuedDateLc, setPiIssuedDateLc] = useState("");

  const openModal = (fileUrl, type, name) => {
    setSelectedFileUrl(fileUrl);
    setFileType(type);
    setFileName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFileUrl(null);
    setFileType('');
    setFileName('');
  };

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue?.piIssuedList) {
        // Map piIssuedList to match React-Select's options format
        const options = selectedValue.piIssuedList.map(pi => ({
          value: pi.id,
          label: pi.piNumber,
          piIssueDate: pi.piIssueDate, // Include issue date for easy access
        }));
        setPiOptions(options);

        // Set the latest PI number as the default selected value
        if (options.length > 0) {
          const latestPi = options[0];
          setSelectedPiNumber(latestPi);
          setPiIssuedDateLc(latestPi.piIssueDate); // Set issue date for the default selected value
        }
      }
    }
  }, [selectedValue]);

  const handleAddRow = () => {
    const defaultPiOption = piOptions[0] || null; // Get the default option or null
    const currentDate = new Date().toISOString().split("T")[0];
    const newRow = { id: 0, date: currentDate, lcNumber: "", attach: '', poAmt: '', selectedPiNumber: defaultPiOption, piIssuedDateLc: defaultPiOption ? defaultPiOption.piIssueDate : "" };
    setRows([...rows, newRow]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      rowAttach: [...prevErrors.rowAttach, false],
    }));
  };

  const handlePiNumberChange = (index, selectedOption) => {
    const updatedRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        return {
          ...row,
          selectedPiNumber: selectedOption,
          piIssuedDateLc: selectedOption ? selectedOption.piIssueDate : "", // Update issue date based on the selected option
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handlePiNumberLc = (option) => {
    if (option) {
      setSelectedPiNumber(option);
      setPiIssuedDateLc(option.piIssueDate); // Update issue date based on the selected PI number
    } else {
      setSelectedPiNumber(null);
      setPiIssuedDateLc(""); // Clear the issue date if no PI number is selected
    }
  };

  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  }, []);

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    const updatedErrors = errors.rowAttach.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
    setErrors((prevErrors) => ({ ...prevErrors, rowAttach: updatedErrors }));
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);

    // Clear error if an image is uploaded
    if (field === "attach") {
      const updatedErrors = [...errors.rowAttach];
      updatedErrors[index] = !value;
      setErrors((prevErrors) => ({ ...prevErrors, rowAttach: updatedErrors }));
    }
  };

  const handleMainAttachChange = (value) => {
    setAttach(value);

    // Clear error if an image is uploaded
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, mainAttach: false }));
    }
  };

  const token = localStorage.getItem('DIYAN_IE_authToken');

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    // Determine if all main and row fields are empty
    const isAllEmpty = !lcNumber && !attach && rows.every((row) => !row.lcNumber && !row.attach);

    // Validate the main attach field
    const mainAttachError = !isAllEmpty && !attach;
    if (mainAttachError) hasError = true;

    // Validate each row's attach field
    const rowAttachErrors = rows.map((row) => !row.attach);
    if (!isAllEmpty && rowAttachErrors.includes(true)) hasError = true;

    if (hasError) {
      setErrors({ mainAttach: mainAttachError, rowAttach: rowAttachErrors });
      setUpdatePopup(false)
      return;
    }
    setIsLoading(true);

    try {
      // Conditionally create rows with LC data if any is provided
      const rowsWithDynamicIds = rows.map((row) => ({
        id: 0,
        receivedDate: row.date || null,
        lcNumber: row.lcNumber || "",
        poAmount: poAmt || 0,
        imageName: "",
        piNumber: row.selectedPiNumber.label || "",
        originalFileName: row.attach.name,
        image_Base64: row.attach.base64
      }));

      // Include the main LC received data if any field is filled
      let lcReceivedDetail = [];
      if (lcNumber || attach) {
        lcReceivedDetail.push({
          id: 0,
          receivedDate: date || null,
          lcNumber: lcNumber || "",
          poAmount: poAmt || 0,
          piNumber: selectedPiNumber.label || "",
          imageName: "",
          originalFileName: attach.name,
          image_Base64: attach.base64
        });
      }

      // Add rows if any exist
      if (rowsWithDynamicIds.length > 0) {
        lcReceivedDetail = [...lcReceivedDetail, ...rowsWithDynamicIds];
      }

      const requestBody = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 2,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: [],
          // Only include lcReceivedDetail if it has data
          ...(lcReceivedDetail.length > 0 && { lcReceivedDetail })
        }
      };


      console.log("testing--->", requestBody);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup(false);
        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const handleEditButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordEdit(true);
  };

  const handleViewButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordView(true);
  };


  const handleUpdateRecord = (updatedRecord) => {
    const updatedDetails = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map(item =>
      item.id === updatedRecord.id ? updatedRecord : item
    );

    setSelectedValue(prev => ({
      ...prev,
      paymentReceived_Or_LCReceivedDetail: {
        ...prev.paymentReceived_Or_LCReceivedDetail,
        lcReceivedDetail: updatedDetails
      }
    }));
  };

  const totalRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const startRecordIndex = (currentPage - 1) * recordsPerPage;
  const endRecordIndex = startRecordIndex + recordsPerPage;
  const currentRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail.slice(startRecordIndex, endRecordIndex) || [];

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  // =========== 11 
  const [openAccordionIndex1, setOpenAccordionIndex1] = useState(0);
  const [iNo1, setINo1] = useState("");
  const [amt1, setAmt1] = useState("");
  const [date1, setDate1] = useState("");
  const [date1Ig, setDate1Ig] = useState("");
  const [rows1, setRows1] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [paymentTermsData1, setPaymentsTermsData1] = useState([]);
  const [currencyTypeData1, setCurrencyTypeData1] = useState([]);
  const [paymentRecivedData1, setPaymentRecivedData1] = useState([]);
  const [paymentTermsId1, setPaymentsTermsId1] = useState("");
  const [paymentTermsName1, setPaymentsTermsName1] = useState("");
  const [currencyTypeId1, setCurrencyTypeId1] = useState("");
  const [currencyTypeName1, setCurrencyTypeName1] = useState("");
  const [paymentRecivedId1, setPaymentRecivedId1] = useState("");

  const [poAmt1, setPoAmt1] = useState("");
  const [payType1, setPayType1] = useState("");
  const [remainAmt, setRemainAmt] = useState(0)
  const [recordView1, setRecordView1] = useState("");
  const [recordEdit1, setRecordEdit1] = useState("");
  const [recordAttachment1, setRecordAttachment1] = useState("");
  const [selectedRecord1, setSelectedRecord1] = useState(null);

  const [currentPage1, setCurrentPage1] = useState(1);
  const recordsPerPage1 = 5;
  const [disableAddRow, setDisableAddRow] = useState(false);
  const [error1, setError1] = useState("");
  const [totalAmt1, setTotalAmt1] = useState("");
  const [existingTotalAmt, setExistingTotalAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [piOptionsPy, setPiOptionsPy] = useState([]);
  const [poAmt1Py, setPoAmt1Py] = useState("");
  const [selectedPiNumberPy, setSelectedPiNumberPy] = useState(null);
  const [piIssuedDatePy, setPiIssuedDatePy] = useState("");

  const token1 = localStorage.getItem('DIYAN_IE_authToken');
  const dataActive1 = { searchText: "", isActive: true };

  const fetchData1 = async (url, setData, errorMsg) => {
    setIsLoading1(true);
    try {
      const response = await axios.post(url, dataActive1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData1, "Error fetching Payment Terms Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData1, "Error fetching Currency Type Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData1, "Error fetching Payment Received Data");
  }, []);

  const [bankRefNo, setBankRefNo] = useState("");
  const [conAttach, setConAttach] = useState([]);

  const handleConAttachChange = (files) => {
    setConAttach(files);
  };

  useEffect(() => {
    if (selectedValue) {
      const initialPoAmt = selectedValue.pO_Amount;
      const existingPayments = selectedValue.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail || [];

      // Calculate total of existing received payments
      const totalReceivedFromRecords = existingPayments.reduce(
        (sum, record) => sum + (parseFloat(record.amount) || 0),
        0
      );

      setPoAmt1(initialPoAmt);
      setExistingTotalAmt(totalReceivedFromRecords);
      setTotalAmt(totalReceivedFromRecords); // Initialize totalAmt with existing total
      setRemainAmt(initialPoAmt - totalReceivedFromRecords);

      if (selectedValue?.piIssuedList) {
        // Map piIssuedList to match React-Select's options format
        const options = selectedValue.piIssuedList.map(pi => ({
          value: pi.id,
          label: pi.piNumber,
          piIssueDate: pi.piIssueDate, // Include issue date for easy access
        }));
        setPiOptionsPy(options);

        // Set the latest PI number as the default selected value
        if (options.length > 0) {
          const latestPi = options[0];
          setSelectedPiNumberPy(latestPi);
          setPiIssuedDatePy(latestPi.piIssueDate); // Set issue date for the default selected value
        }
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    // Create a new date object in UTC and convert it to IST
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    const formattedDate = istDate.toISOString().slice(0, 16);
    setDate1(formattedDate);
    setDate1Ig(formattedDate);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setCurrencyTypeId1(selectedValue.pO_CurrencyTypeId);
      setCurrencyTypeName1(selectedValue.pO_CurrencyType);
      setPaymentsTermsId1(selectedValue.pO_PaymentTermsId);
      setPaymentsTermsName1(selectedValue.pO_PaymentTerms);
    }
  }, [selectedValue])

  const handleViewButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordView1(true);
  };

  const handleEditButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordEdit1(true);
  };

  const totalRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages1 = Math.ceil(totalRecords1 / recordsPerPage1);

  const startRecordIndex1 = (currentPage1 - 1) * recordsPerPage1;
  const endRecordIndex1 = startRecordIndex1 + recordsPerPage1;
  const currentRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail.slice(startRecordIndex1, endRecordIndex1) || [];

  const handleNextPage1 = () => {
    setCurrentPage1((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage1 = () => {
    setCurrentPage1((prev) => Math.max(prev - 1, 1));
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteRecordPayment = async (id) => {
    if (isDeleting) return; // Prevent multiple calls
    setIsDeleting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePaymentReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevSelectedValue.paymentReceived_Or_LCReceivedDetail,
            paymentReceivedDetail: prevSelectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.filter(
              (item) => item.id !== id
            ),
          },
        }));

        // toast.success('Record deleted successfully', { autoClose: 5000 });
      } else {
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      toast.error('Error deleting the record');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteRecordLc = async (id) => {
    console.log(`Attempting to delete record with ID: ${id}`); // Log the ID being passed

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteLCReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Record with ID ${id} deleted successfully`); // Log successful deletion
        // toast.success('Record deleted successfully');

        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevSelectedValue.paymentReceived_Or_LCReceivedDetail,
            lcReceivedDetail: prevSelectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.filter(
              (item) => item.id !== id
            ),
          },
        }));
      } else {
        console.error(`Failed to delete the record with ID ${id}:`, response.statusText); // Log failure details
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      console.error(`Error deleting the record with ID ${id}:`, error); // Log error details
      toast.error('Error deleting the record');
    }
  };

  const handleAmt1Change = (value) => {
    if (value === "") {
      // Reset amt1 and recalculate amounts when cleared
      setAmt1("");
      setTotalAmt(existingTotalAmt); // Reset total amount to the initial value
      setRemainAmt(poAmt1 - existingTotalAmt); // Recalculate remaining amount
      return;
    }

    const newReceivedAmt = parseFloat(value) || 0; // Convert input to a number
    let errorMessage = "";

    // Validation: Payment cannot be negative
    if (newReceivedAmt < 0) {
      errorMessage = "Payment received cannot be negative.";
    }

    if (errorMessage) {
      setError1(errorMessage);
      return;
    }

    setError1(""); // Clear any existing errors

    // Calculate total amount (existing records total + current payment)
    const updatedTotalAmt = existingTotalAmt + newReceivedAmt;

    // Calculate remaining amount (PO amount - updated total amount)
    const updatedRemainAmt = poAmt1 - updatedTotalAmt;

    // Update states
    setAmt1(newReceivedAmt); // Keep the entered value as a number
    setTotalAmt(updatedTotalAmt);
    setRemainAmt(updatedRemainAmt);
  };

  const [invoiceError, setInvoiceError] = useState("");
  const [paymentTypeError, setPaymentTypeError] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value.toUpperCase();

    // Check if the value contains only alphanumeric characters
    if (/^[a-zA-Z0-9]*$/.test(newValue)) {
      setInvoiceError('');
      setINo1(newValue);
    } else {
      setInvoiceError('Only alphanumeric characters are allowed');
    }
  };

  const handlePiNumber = (option) => {
    if (option) {
      setSelectedPiNumberPy(option);
      setPiIssuedDatePy(option.piIssueDate); // Update issue date based on the selected PI number
    } else {
      setSelectedPiNumberPy(null);
      setPiIssuedDatePy(""); // Clear the issue date if no PI number is selected
    }
  };

  const setDefaultDate = () => {
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    return istDate.toISOString().slice(0, 16);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // Reset errors
    setInvoiceError("");
    setPaymentTypeError("");

    // Determine if all fields are empty
    const isAllEmpty = !amt1 && !paymentRecivedId1;

    // Validate fields
    let hasError = false;
    // if (!isAllEmpty && !iNo1) {
    //   setInvoiceError("Invoice Number is required");
    //   hasError = true;
    // }
    if (!isAllEmpty && !paymentRecivedId1) {
      setPaymentTypeError("Payment Type is required");
      hasError = true;
    }

    if (hasError) {
      setUpdatePopup1(false)
      return; // Prevent submission if there are errors
    }
    setIsLoading1(true);

    try {
      // Construct paymentReceivedDetail only if there's at least one field with a non-default value

      const effectiveDate = date1 || setDefaultDate();
      const effectiveDate2 = date1Ig || setDefaultDate();

      const paymentReceivedDetail = [];
      if (!isAllEmpty) {
        paymentReceivedDetail.push({
          id: 0,
          invoiceNumber: iNo1 || "",
          paymentTermsId: paymentTermsId1 || 0,
          paymentReceivedDate: effectiveDate,
          currencyTypeId: currencyTypeId1 || 0,
          amount: amt1 || 0,
          paymentReceivedId: paymentRecivedId1 || 0,
          poAmount: poAmt1 || 0,
          remainingAmount: remainAmt || 0,
          totalReceivedAmount: totalAmt || 0,
          bankReferenceNumber: bankRefNo || "",
          invoiceGenerateDate: effectiveDate2,
          piNumber: selectedPiNumberPy.label || "",
          purchaseOrderPaymentReceivedImagesList: conAttach
            .filter(file => file.base64 || file.name) // Include only if base64 or name is present
            .map((file) => ({
              id: 0,
              imageFileName: file.name,
              imageOriginalFileName: file.name,
              image_Base64: file.base64,
            })),
        });
      }

      const requestBody1 = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 1,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: paymentReceivedDetail.length > 0 ? paymentReceivedDetail : undefined,
          lcReceivedDetail: []
        }
      };

      console.log("Request Body: ", requestBody1);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
        body: JSON.stringify(requestBody1),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup1(false);
        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }

    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading1(false);
    }
  };

  const formatDateTest2 = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // for 24-hour format
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Direct Payment / LC</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex.includes(0)}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex.includes(1)}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex.includes(2)}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Direct Payment / LC"}
            isOpen={openAccordionIndex.includes(3)}
            onClick={() => handleAccordionClick(3)}
          >
            <div className="max-w-full grid grid-cols-1 gap-4 md:grid-cols-1 w-[1000px]">
              {payType === 1 ? (
                <div className="mt-2 flex gap-4 items-center justify-between">
                  <div>
                    <CustomCheckBox4
                      label={"Direct Payment"}
                      state={checkboxPi}
                      setState={setCheckboxPi}
                      readonly
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <CustomCheckBox4
                    label={"LC"}
                    state={checkbox}
                    setState={handleCheckboxChange}
                    readonly
                  />
                </div>
              )}
            </div>

            {checkboxPi && (
              <>
                <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-3 w-[1000px]">
                  <div className="">
                    <div className="flex flex-col gap-1">
                      <label className="text-xs font-400">
                        Currency Type
                      </label>
                      <Select
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                        menuPosition="fixed"
                        isDisabled={true}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: '#e5e7eb',
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div>
                    <CustomInputNumberDecimal label="PO Amount" value={poAmt1} readOnly isRequired={false} />
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      <label className="text-xs font-400">PI Number</label>
                      <Select
                        options={piOptionsPy}
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        value={selectedPiNumberPy} // Show the selected value
                        onChange={handlePiNumber} // Update selected value on change
                        isClearable
                        menuPosition="fixed"
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: '#e5e7eb',
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      <label htmlFor="date" className="text-xs font-[400]">
                        Pi Issued Date
                      </label>
                      <input
                        type="datetime-local"
                        disabled
                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                        value={piIssuedDatePy}
                      />
                    </div>
                  </div>
                  <div className="">
                    <CustomInputNumberDecimal label="Total Received Amount" value={totalAmt} readOnly isRequired={false} />
                  </div>
                  <div>
                    <CustomInputNumberDecimal label="Remaining Amount" value={remainAmt} readOnly isRequired={false} />
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      <label className="text-xs font-400">
                        Payment Terms
                      </label>
                      <Select
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                        menuPosition="fixed"
                        isDisabled={true}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: '#e5e7eb',
                          },
                        })}
                      />
                    </div>
                  </div>
                  {/* <div className="">
                    <div className="flex flex-col gap-1">
                      <label htmlFor="date" className="text-xs font-[400]">
                        Invoice Generated Date
                      </label>
                      <input
                        type="datetime-local"
                        // required
                        className={`p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs text-black bg-white`}
                        value={date1Ig}
                        onChange={(e) => setDate1Ig(e.target.value) || ""}
                        onBlur={() => {
                          if (!date1) setDate1Ig(setDefaultDate());
                        }}
                      />
                    </div>
                  </div> */}
                  {/* <div>
                    <div className="flex flex-col gap-1">
                      <label htmlFor="id" className="text-xs font-[400]">
                        Invoice Number<span className="text-red-500 gap-3">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={iNo1}
                        className={`p-2 border rounded grow min-w-[14rem] text-xs placeholder:text-xs uppercase`}
                        id="id"
                        placeholder={"Enter"}
                        onChange={handleChange}
                      />
                      {invoiceError && <span className="text-red-500 text-xs">{invoiceError}</span>}
                    </div>
                  </div> */}
                  <div className="">
                    <div className="flex flex-col gap-1">
                      <label htmlFor="date" className="text-xs font-[400]">
                        Payment received Date
                      </label>
                      <input
                        type="datetime-local"
                        // required
                        className={`p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs text-black bg-white`}
                        value={date1}
                        onChange={(e) => setDate1(e.target.value) || ""}
                        onBlur={() => {
                          if (!date1) setDate(setDefaultDate());
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="flex flex-col gap-1">
                      <label htmlFor="id" className="text-xs font-400">
                        Payment Type<span className="text-red-500 gap-3">*</span>
                      </label>
                      <Select
                        options={paymentRecivedData1.map((cData) => ({
                          value: cData.id,
                          label: cData.paymentReceived,
                        }))}
                        onChange={(option) => {
                          setPaymentRecivedId1(option ? option.value : "");
                          if (option) {
                            setPaymentTypeError(""); // Clear error when a valid value is selected
                          }
                        }}
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        isSearchable
                        isClearable
                        menuPosition={"fixed"}
                        closeMenuOnSelect={true}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: "#e5e7eb",
                          },
                        })}
                      />
                      {paymentTypeError && <span className="text-red-500 text-xs">{paymentTypeError}</span>}
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      <label htmlFor="amt1" className="text-xs font-[400]">Payment Received</label>
                      <input
                        type="number"
                        step="0.01"
                        className={`p-2 border rounded grow min-w-[14rem] text-xs text-black bg-white ${error1 ? 'border-red-500' : ''}`}
                        id="amt1"
                        value={amt1}
                        placeholder="Enter"
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // Allow digits and one decimal
                          handleAmt1Change(sanitizedValue);
                        }}
                      />
                      {error1 && <span className="text-red-500 text-xs">{error1}</span>}
                    </div>
                  </div>
                  <div>
                    <CustomTextField2 label="Bank Reference Number" placeholder="Enter" value={bankRefNo} onChange={setBankRefNo} isRequired={false} />
                  </div>
                  <div>
                    <CustomImagePdfMultiple
                      label="Attachment"
                      id="poissuede435345ed4565it-edit-0"
                      value={conAttach}
                      isRequired={false}
                      onChange={handleConAttachChange}
                    />
                  </div>
                </div>

                <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
                  <table className="w-full custom-table" border={1}>
                    <thead>
                      <tr className="table-heading">
                        <td className="">S.No.</td>
                        <td>Action</td>
                        <td>Currency Type</td>
                        <td>PO Amount</td>
                        <td>Pi Number</td>
                        <td>Pi Issued Date</td>
                        <td>Total Received Amount</td>
                        <td>Remaining Amount</td>
                        <td>Payment Terms</td>
                        {/* <td>Invoice Generated Date</td> */}
                        {/* <td>Invoice Number</td> */}
                        <td>Payment received Date</td>
                        <td>Payment Type</td>
                        <td>Payment Received</td>
                        <td>Bank Reference Number</td>
                        <td>Attachment</td>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentRecords1.length > 0 ? (
                        currentRecords1.map((item, index) => (
                          <tr key={index}>
                            <td className="text-xs">{startRecordIndex1 + index + 1}</td>
                            <td className="text-xs">
                              <div className="gap-2">
                                <Tooltip title="EDIT" arrow placement="bottom">
                                  <span>
                                    <CustomButton1
                                      className="bg-sixt text-white grow max-w-[50px]"
                                      icon={<BiEdit />}
                                      onClick={() => handleEditButtonClick1(item.id)}
                                    />
                                  </span>
                                </Tooltip>

                                <Tooltip title="VIEW" arrow placement="bottom">
                                  <span>
                                    <CustomButton4
                                      className="bg-eye text-white grow max-w-[50px]"
                                      icon={<BsEyeFill />}
                                      onClick={() => handleViewButtonClick1(item.id)}
                                    />
                                  </span>
                                </Tooltip>

                                <Tooltip title="DELETE" arrow placement="bottom">
                                  <span>
                                    <Button
                                      variant={"contained"}
                                      size={"small"}
                                      className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                      type={"button"}
                                      onClick={() => handleDeleteRecordPayment(item.id)}
                                    >
                                      <RiDeleteBin6Line />
                                    </Button>
                                  </span>
                                </Tooltip>

                              </div>
                            </td>
                            <td className="text-xs">{item.currencyType}</td>
                            <td className="text-xs">{item.poAmount}</td>
                            <td className="text-xs">{item.piNumber}</td>
                            <td></td>
                            <td className="text-xs">{item.totalReceivedAmount}</td>
                            <td className="text-xs">{item.remainingAmount}</td>
                            <td className="text-xs">{item.paymentTerms}</td>
                            {/* <td></td> */}
                            {/* <td className="text-xs">{item.invoiceNumber}</td> */}
                            <td className="text-xs">{formatDateTest2(item.paymentReceivedDate)}</td>
                            <td className="text-xs">{item.paymentReceived}</td>
                            <td className="text-xs">{item.amount}</td>
                            <td className="text-xs">{item.bankReferenceNumber}</td>
                            <td className="text-xs">
                              <Tooltip title="Open" arrow placement="bottom">
                                <div
                                  className="underline cursor-pointer"
                                  onClick={() => {
                                    setRecordAttachment1(true);
                                    setSelectedImages(item.purchaseOrderPaymentReceivedImagesList); // Pass images to the modal
                                  }}
                                >
                                  Click
                                </div>
                              </Tooltip>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="text-xs text-center p-2 ">No Payment received details available.</div>
                      )}
                    </tbody>
                  </table>

                </div>

                <div className="flex items-center justify-between mt-5">
                  <div className="text-sm text-black font-normal">
                    Displaying {startRecordIndex1 + 1} to {Math.min(endRecordIndex1, totalRecords1)} of {totalRecords1} records
                  </div>

                  <div className="flex gap-2">
                    <div className="flex items-center">
                      <button
                        className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                        onClick={handlePrevPage1}
                        disabled={currentPage1 === 1}
                      >
                        Previous
                      </button>

                      <button
                        className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                        onClick={handleNextPage1}
                        disabled={currentPage1 === totalPages1}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

                <div className="max-w-full grid grid-cols-1  gap-x-2 md:grid-cols-3  w-[1000px] mt-5">
                  <CustomCheckBox2
                    label={"Payment / LC Closed"}
                    state={prlCloseAll}
                    setState={handleCheckboxChange}
                  ></CustomCheckBox2>

                  {prlCloseAll && (
                    <CustomViewLabel
                      label="Payment / LC Closed Date & Time"
                      value={endFormatDate(endCloseDateTime)}
                    />
                  )}
                </div>

                <div className="flex justify-center gap-5 pt-3">
                  <div onClick={() => setUpdatePopup1(true)}>
                    <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                  </div>
                  <div onClick={onCancel}>
                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </>
            )}

            {checkbox && (
              <>
                {/* <EditLC /> */}
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3 w-[1000px]">
                  <div className="">
                    <div className="flex flex-col gap-1">
                      <label className="text-xs font-400">
                        Currency Type
                      </label>
                      <Select
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                        menuPosition="fixed"
                        isDisabled={true}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: '#e5e7eb',
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div>
                    <CustomInputNumberDecimal
                      label={"PO Amount"}
                      placeholder={"ENTER"}
                      value={poAmt}
                      onChange={setPoAmt}
                      readOnly
                      isRequired={false}
                    ></CustomInputNumberDecimal>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-xs font-400">PI Number</label>
                    <Select
                      options={piOptions}
                      className="text-black text-xs select-bar uppercase"
                      id="id"
                      value={selectedPiNumber} // Show the selected value
                      // onChange={option => setSelectedPiNumber(option)} // Update selected value on change
                      onChange={handlePiNumberLc} // Update selected value and issue date on change
                      menuPosition="fixed"
                      theme={theme => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary: '#e5e7eb',
                        },
                      })}
                    />
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      <label htmlFor="date" className="text-xs font-[400]">
                        Pi Issued Date
                      </label>
                      <input
                        type="datetime-local"
                        disabled
                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                        value={piIssuedDateLc}
                      />
                    </div>
                  </div>
                  <div>
                    <CustomInputNumberDecimal label="Remaining Amount" value={remainAmtLc} readOnly isRequired={false} />
                  </div>
                  <div>
                    <div className="flex flex-col gap-1">
                      <label className="text-xs font-400">
                        Payment Terms
                      </label>
                      <Select
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                        menuPosition="fixed"
                        isDisabled={true}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: '#e5e7eb',
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className="">
                    <CustomDate
                      label="LC Received"
                      value={date}
                      onChange={setDate}
                    />
                  </div>
                  <div className="mt-1">
                    <CustomMultitext
                      label="LC Number"
                      value={lcNumber}
                      onChange={setLcNumber}
                      placeholder="Enter"
                      isRequired={false}
                    />
                  </div>
                  <div className="">
                    <CustomBrowse
                      label="LC Received Upload"
                      id={`poissu32423ededit-edit-0`}
                      value={attach}
                      onChange={handleMainAttachChange}
                    />
                    {errors.mainAttach && <p className="text-red-500 text-xs">LC Received Upload is required.</p>}
                  </div>
                  <div className="flex mt-5">
                    <Tooltip title="ADD" arrow placement="bottom">
                      <span>
                        <button onClick={handleAddRow}>
                          <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                        </button>
                      </span>
                    </Tooltip>
                  </div>
                </div>

                {rows.map((row, index) => (
                  <div key={index} className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3 w-[1000px]">
                    <div className="">
                      <div className="flex flex-col gap-1">
                        <label className="text-xs font-400">
                          Currency Type
                        </label>
                        <Select
                          className="text-black text-xs select-bar uppercase"
                          id="id"
                          value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                          menuPosition="fixed"
                          isDisabled={true}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary: '#e5e7eb',
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div>
                      <CustomTextField2
                        label={"PO Amount"}
                        placeholder={"ENTER"}
                        value={poAmt}
                        onChange={setPoAmt}
                        readOnly
                        isRequired={false}
                      ></CustomTextField2>
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="text-xs font-400">PI Number</label>
                      <Select
                        options={piOptions}
                        className="text-black text-xs select-bar uppercase"
                        id="id"
                        value={row.selectedPiNumber} // Row-specific value
                        onChange={(option) => handlePiNumberChange(index, option)} // Update only the specific row
                        menuPosition="fixed"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: "#e5e7eb",
                          },
                        })}
                      />
                    </div>
                    <div>
                      <div className="flex flex-col gap-1">
                        <label htmlFor="date" className="text-xs font-[400]">
                          Pi Issued Date
                        </label>
                        <input
                          type="datetime-local"
                          disabled
                          className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                          value={row.piIssuedDateLc}
                        />
                      </div>
                    </div>
                    <div>
                      <CustomInputNumberDecimal label="Remaining Amount" value={remainAmtLc} readOnly isRequired={false} />
                    </div>
                    <div>
                      <div className="flex flex-col gap-1">
                        <label className="text-xs font-400">
                          Payment Terms
                        </label>
                        <Select
                          className="text-black text-xs select-bar uppercase"
                          id="id"
                          value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                          menuPosition="fixed"
                          isDisabled={true}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary: '#e5e7eb',
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="">
                      <CustomDate
                        label="LC Received"
                        value={row.date}
                        onChange={(value) => handleRowChange(index, 'date', value)}
                      />
                    </div>
                    <div className="mt-1 ">
                      <CustomMultitext
                        label="LC Number"
                        value={row.lcNumber}
                        onChange={(value) => handleRowChange(index, 'lcNumber', value)}
                        placeholder="Enter"
                        isRequired={false}
                      />
                    </div>
                    <div className="">
                      <CustomBrowse
                        id={`poissudgfededit-edit-${index}`}
                        label="LC Received Upload"
                        value={row.attach}
                        onChange={(value) => handleRowChange(index, 'attach', value)}
                      />
                      {errors.rowAttach[index] && <p className="text-red-500 text-xs">This upload is required.</p>}
                    </div>
                    <div className="flex mt-5 ">
                      <Tooltip title="REMOVE" arrow placement="bottom">
                        <span>
                          <button onClick={() => handleRemoveRow(index)}>
                            <CiSquareRemove className="h-8 w-8 txt-prp-color" />
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ))}

                <div>
                  <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
                    <table className="w-full custom-table" border={1}>
                      <thead>
                        <tr className="table-heading">
                          <td className="">S.No.</td>
                          <td>Action</td>
                          <td>Currency Type</td>
                          <td>PO Amount</td>
                          <td>Pi Number</td>
                          <td>Pi Issued Date</td>
                          <td>Remaining Amount</td>
                          <td>Payment Terms</td>
                          <td>LC Received</td>
                          <td>Lc Number</td>
                          <td>LC Received Upload</td>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentRecords.length > 0 ? (
                          currentRecords.map((item, index) => (
                            <tr key={index}>
                              <td className="text-xs">{index + 1}</td>
                              <td className="text-xs">
                                <div className="gap-2">
                                  <Tooltip title="EDIT" arrow placement="bottom">
                                    <span>
                                      <CustomButton1
                                        className="bg-sixt text-white grow max-w-[50px]"
                                        icon={<BiEdit />}
                                        onClick={() => handleEditButtonClick(item.id)}
                                      />
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="VIEW" arrow placement="bottom">
                                    <span>
                                      <CustomButton4
                                        className="bg-eye text-white grow max-w-[50px]"
                                        icon={<BsEyeFill />}
                                        onClick={() => handleViewButtonClick(item.id)}
                                      />
                                    </span>
                                  </Tooltip>


                                  <Tooltip title="DELETE" arrow placement="bottom">
                                    <span>
                                      <Button
                                        variant={"contained"}
                                        size={"small"}
                                        className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                        type={"button"}
                                        onClick={() => handleDeleteRecordLc(item.id)}
                                      >
                                        <RiDeleteBin6Line />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                              </td>
                              <td>{selectedValue?.pO_CurrencyType || '-'}</td>
                              <td>{item?.poAmount ?? '-'}</td>
                              <td> {item?.piNumber ?? '-'}</td>
                              <td> </td>
                              <td>0</td>
                              <td>{selectedValue?.pO_PaymentTerms || '-'}</td>
                              <td className="text-xs">{formatDateTest(item.receivedDate)}</td>
                              <td className="text-xs">{item?.lcNumber ?? '-'}</td>
                              <td>
                                <div>
                                  <div className='flex justify-between items-center'>
                                    <div
                                      onClick={() => {
                                        const fileType = item.originalFileName?.endsWith('.pdf') ? 'application/pdf' : 'image/';
                                        const fileUrl = item.imageURL;

                                        if (fileUrl) {
                                          const newWindow = window.open(fileUrl, '_blank');
                                          if (newWindow) newWindow.focus();
                                        }
                                      }}
                                    >
                                      <Tooltip title="View Upload" arrow placement="bottom">
                                        <span>
                                          <p className='txt-prp-color underline cursor-pointer text-xs'>
                                            {item?.originalFileName ?? '-'}
                                          </p>
                                        </span>
                                      </Tooltip>
                                    </div>
                                    {item?.imageURL && (
                                      <div>
                                        {item.originalFileName?.endsWith(".pdf") ? (
                                          <embed
                                            src={item.imageURL}
                                            type="application/pdf"
                                            className="w-10 h-10 border border-[#3D3D3D66]"
                                          />
                                        ) : (
                                          <img
                                            src={item.imageURL}
                                            alt="LC Received Upload"
                                            className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center"
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="text-xs text-center p-2 ">No Lc received details available.</div>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="flex items-center justify-between mt-5">
                    <div className="text-sm text-black font-normal">
                      Displaying {startRecordIndex + 1} to {Math.min(endRecordIndex, totalRecords)} of {totalRecords} records
                    </div>

                    <div className="flex gap-2">
                      <div className="flex items-center">
                        <button
                          className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                          onClick={handlePrevPage}
                          disabled={currentPage1 === 1}
                        >
                          Previous
                        </button>

                        <button
                          className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                          onClick={handleNextPage}
                          disabled={currentPage1 === totalPages1}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="max-w-full grid grid-cols-1  gap-x-2 md:grid-cols-3  w-[1000px] mt-5">
                  <CustomCheckBox2
                    label={"Payment / LC Closed"}
                    state={prlCloseAll}
                    setState={handleCheckboxChange}
                  ></CustomCheckBox2>

                  {prlCloseAll && (
                    <CustomViewLabel
                      label="Payment / LC Closed Date & Time"
                      value={endFormatDate(endCloseDateTime)}
                    />
                  )}
                </div>

                <div className="flex justify-center gap-5 pt-3">
                  <div onClick={() => setUpdatePopup(true)}>
                    <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                  </div>
                  <div onClick={onCancel}>
                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </>
            )}
          </Accordion>
        </div>
      </div >

      {recordEdit && (
        <PopUp>
          <LCReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord}
            onUpdate={handleUpdateRecord}
            onCancel={() => {
              setRecordEdit(false)
            }}
          />
        </PopUp>
      )
      }

      {recordView && (
        <PopUp>
          <LCReceivedEditInView
            selectedValue={selectedValue}
            record={selectedRecord}
            onCancel={() => setRecordView(false)}
          />
        </PopUp>
      )}

      {
        recordEdit1 && (
          <PopUp>
            <PaymentReceivedEditInEdit
              selectedValue={selectedValue}
              record={selectedRecord1}
              onCancel={() => {
                setRecordEdit1(false)
              }}
            />
          </PopUp>
        )
      }

      {
        recordAttachment1 && (
          <PopUp>
            <PaymentReceivedAttachmentImage
              selectedValue={selectedValue}
              selectedImages={selectedImages} // Pass images here
              record={selectedRecord1}
              onCancel={() => {
                setRecordAttachment1(false)
              }}
            />
          </PopUp>
        )
      }

      {
        recordView1 && (
          <PopUp>
            <PaymentReceivedEditInView
              record={selectedRecord1}
              onCancel={() => setRecordView1(false)}
            />
          </PopUp>
        )
      }

      {
        updatePopup && (
          <PopUp>
            <div className="pb-2 bg-white border rounded-lg">
              <div className="flex flex-col p-4">
                <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                <div className="flex justify-end gap-2">
                  <div onClick={handleSubmit}>
                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                  </div>
                  <div onClick={() => setUpdatePopup(false)}>
                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </div>
            </div>
          </PopUp>
        )
      }

      {
        updatePopup1 && (
          <PopUp>
            <div className="pb-2 bg-white border rounded-lg">
              <div className="flex flex-col p-4">
                <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                <div className="flex justify-end gap-2">
                  <div onClick={handleSubmit1}>
                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                  </div>
                  <div onClick={() => setUpdatePopup1(false)}>
                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </div>
            </div>
          </PopUp>
        )
      }

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="View File"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="coman-modal-img-end">
          <p>{fileName}</p>
          <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
            <MdCancel size={24} />
          </button>
        </div>
        {fileType === 'image/' && selectedFileUrl && (
          <img src={selectedFileUrl} alt="Enlarged View" className="w-full h-auto" />
        )}
        {fileType === 'application/pdf' && selectedFileUrl && (
          <embed src={selectedFileUrl} type="application/pdf" className="w-full h-96" />
        )}
      </Modal>

      <ToastContainer autoClose={2000} />
    </>
  )
}

