// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import '../../../assets/css/selectbar.css';
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";
import Select from 'react-select';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomInputNumber from '../../general-components/CustomInputNumber.component'
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton4 from "../../general-components/CustomButton4.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

import { BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";

import PaymentReceivedEditInView from "./PaymentReceivedEditInView";
import PaymentReceivedEditInEdit from "./PaymentReceivedEditInEdit";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomImageMultipleZip from "../../general-components/CustomImageMultipleZip.component";
import CustomImagePdfMultiple from "../../general-components/CustomImagePdfMultiple.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import PaymentReceivedAttachmentImage from "./PaymentReceivedAttachmentImage";
import CustomInputNumberDecimal from "../../general-components/CustomInputNumberDecimal.component";

export default function PaymentReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {
  const [payCheck, setPayCheck] = useState(true);
  const [openAccordionIndex, setOpenAccordionIndex] = useState([3]);
  const [updatePopup1, setUpdatePopup1] = useState(false);
  const [remainAmt, setRemainAmt] = useState(0)
  const [error1, setError1] = useState("");
  const [paymentTermsId1, setPaymentsTermsId1] = useState("");
  const [paymentTermsName1, setPaymentsTermsName1] = useState("");
  const [currencyTypeId1, setCurrencyTypeId1] = useState("");
  const [currencyTypeName1, setCurrencyTypeName1] = useState("");
  const [recordAttachment1, setRecordAttachment1] = useState("");

  const [iNo1, setINo1] = useState("");
  const [amt1, setAmt1] = useState("");
  const [date1, setDate1] = useState("");
  const [isLoading1, setIsLoading1] = useState(false);
  const [paymentRecivedData1, setPaymentRecivedData1] = useState([]);
  const [paymentRecivedId1, setPaymentRecivedId1] = useState("");

  const [poAmt1, setPoAmt1] = useState("");
  const [recordView1, setRecordView1] = useState("");
  const [recordEdit1, setRecordEdit1] = useState("");
  const [selectedRecord1, setSelectedRecord1] = useState(null);
  const [selectedImages, setSelectedImages] = React.useState([]);

  const [currentPage1, setCurrentPage1] = useState(1);
  const [existingTotalAmt, setExistingTotalAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const recordsPerPage = 5;
  const recordsPerPage1 = 5;

  const [bankRefNo, setBankRefNo] = useState("");
  const [conAttach, setConAttach] = useState([]);
  const [date1Ig, setDate1Ig] = useState("");
  const [piOptionsPy, setPiOptionsPy] = useState([]);
  const [selectedPiNumberPy, setSelectedPiNumberPy] = useState(null);
  const [piIssuedDatePy, setPiIssuedDatePy] = useState("");

  const handleConAttachChange = (files) => {
    setConAttach(files);
  };

  const token = localStorage.getItem('DIYAN_IE_authToken');
  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate1(currentDate);
    // setDate1Ig(currentDate);
  }, []);

  useEffect(() => {
    // Create a new date object in UTC and convert it to IST
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    const formattedDate = istDate.toISOString().slice(0, 16);
    setDate1Ig(formattedDate);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const initialPoAmt = selectedValue.pO_Amount;
      const existingPayments = selectedValue.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail || [];

      // Calculate total of existing received payments
      const totalReceivedFromRecords = existingPayments.reduce(
        (sum, record) => sum + (parseFloat(record.amount) || 0),
        0
      );

      setPoAmt1(initialPoAmt);
      setExistingTotalAmt(totalReceivedFromRecords);
      setTotalAmt(totalReceivedFromRecords); // Initialize totalAmt with existing total
      setRemainAmt(initialPoAmt - totalReceivedFromRecords);

      if (selectedValue?.piIssuedList) {
        // Map piIssuedList to match React-Select's options format
        const options = selectedValue.piIssuedList.map(pi => ({
          value: pi.id,
          label: pi.piNumber,
          piIssueDate: pi.piIssueDate, // Include issue date for easy access
        }));
        setPiOptionsPy(options);

        // Set the latest PI number as the default selected value
        if (options.length > 0) {
          const latestPi = options[0];
          setSelectedPiNumberPy(latestPi);
          setPiIssuedDatePy(latestPi.piIssueDate); // Set issue date for the default selected value
        }
      }
    }
  }, [selectedValue]);

  const dataActive1 = { searchText: "", isActive: true };
  const fetchData1 = async (url, setData, errorMsg) => {
    setIsLoading1(true);
    try {
      const response = await axios.post(url, dataActive1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData1, "Error fetching Payment Received Data");
  }, []);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index) // Close if already open
        : [...prevIndexes, index] // Add to open accordions
    );
  };

  useEffect(() => {
    if (selectedValue) {
      setCurrencyTypeId1(selectedValue.pO_CurrencyTypeId);
      setCurrencyTypeName1(selectedValue.pO_CurrencyType);
      setPaymentsTermsId1(selectedValue.pO_PaymentTermsId);
      setPaymentsTermsName1(selectedValue.pO_PaymentTerms);
    }
  }, [selectedValue])

  const handleViewButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordView1(true);
  };

  const handleEditButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordEdit1(true);
  };

  const totalRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages1 = Math.ceil(totalRecords1 / recordsPerPage1);

  const startRecordIndex1 = (currentPage1 - 1) * recordsPerPage1;
  const endRecordIndex1 = startRecordIndex1 + recordsPerPage1;
  const currentRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail.slice(startRecordIndex1, endRecordIndex1) || [];

  const handleNextPage1 = () => {
    setCurrentPage1((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage1 = () => {
    setCurrentPage1((prev) => Math.max(prev - 1, 1));
  };

  const totalRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const [prlCloseAll, setPlrCloseAll] = useState(false);
  const [endCloseDateTime, setEndCloseDateTime] = useState(null);

  const endFormatDate = (date) =>
    date
      ? new Date(date).toLocaleString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // 12-hour format
      })
      : 'N/A';

  const handleCheckboxChange = (state) => {
    setPlrCloseAll(state);

    // Set the time every time the checkbox is flagged (true)
    if (state) {
      const timestamp = new Date().toISOString();
      setEndCloseDateTime(timestamp); // update time on each flag
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteRecordPayment = async (id) => {
    if (isDeleting) return; // Prevent multiple calls
    setIsDeleting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePaymentReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevSelectedValue.paymentReceived_Or_LCReceivedDetail,
            paymentReceivedDetail: prevSelectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.filter(
              (item) => item.id !== id
            ),
          },
        }));

        // toast.success('Record deleted successfully', { autoClose: 5000 });
      } else {
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      toast.error('Error deleting the record');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAmt1Change1 = (value) => {
    const newReceivedAmt = parseFloat(value) || 0;
    let errorMessage = "";

    if (newReceivedAmt < 0) {
      errorMessage = "Payment received cannot be negative.";
    } else if (newReceivedAmt + existingTotalAmt > poAmt1) {
      errorMessage = "Total payment received cannot exceed the PO amount.";
    }

    if (errorMessage) {
      setError1(errorMessage);
      return;
    }

    setError1("");
    setAmt1(newReceivedAmt);
    setTotalAmt(existingTotalAmt + newReceivedAmt);
    setRemainAmt(poAmt1 - (existingTotalAmt + newReceivedAmt));
  };

  const handleAmt1Change = (value) => {
    if (value === "") {
      // Reset amt1 and recalculate amounts when cleared
      setAmt1("");
      setTotalAmt(existingTotalAmt); // Reset total amount to the initial value
      setRemainAmt(poAmt1 - existingTotalAmt); // Recalculate remaining amount
      return;
    }
  
    const newReceivedAmt = parseFloat(value) || 0; // Convert input to a number
    let errorMessage = "";
  
    // Validation: Payment cannot be negative
    if (newReceivedAmt < 0) {
      errorMessage = "Payment received cannot be negative.";
    }
  
    if (errorMessage) {
      setError1(errorMessage);
      return;
    }
  
    setError1(""); // Clear any existing errors
  
    // Calculate total amount (existing records total + current payment)
    const updatedTotalAmt = existingTotalAmt + newReceivedAmt;
  
    // Calculate remaining amount (PO amount - updated total amount)
    const updatedRemainAmt = poAmt1 - updatedTotalAmt;
  
    // Update states
    setAmt1(newReceivedAmt); // Keep the entered value as a number
    setTotalAmt(updatedTotalAmt);
    setRemainAmt(updatedRemainAmt);
  }; 

  const [invoiceError, setInvoiceError] = useState("");
  const [paymentTypeError, setPaymentTypeError] = useState("");

  useEffect(() => {
    // Create a new date object in UTC and convert it to IST
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    const formattedDate = istDate.toISOString().slice(0, 16);
    setDate1(formattedDate);
  }, []);

  const handleChange = (event) => {
    const newValue = event.target.value.toUpperCase();

    // Check if the value contains only alphanumeric characters
    if (/^[a-zA-Z0-9]*$/.test(newValue)) {
      setInvoiceError('');
      setINo1(newValue);
    } else {
      setInvoiceError('Only alphanumeric characters are allowed');
    }
  };

  const setDefaultDate = () => {
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    return istDate.toISOString().slice(0, 16);
  };

  const handlePiNumber = (option) => {
    if (option) {
      setSelectedPiNumberPy(option);
      setPiIssuedDatePy(option.piIssueDate); // Update issue date based on the selected PI number
    } else {
      setSelectedPiNumberPy(null);
      setPiIssuedDatePy(""); // Clear the issue date if no PI number is selected
    }
  };

  const token1 = localStorage.getItem('DIYAN_IE_authToken');
  const handleSubmit1 = async (e) => {
    e.preventDefault();

    // Reset errors
    setInvoiceError("");
    setPaymentTypeError("");

    // Determine if all fields are empty
    const isAllEmpty = !amt1 && !paymentRecivedId1;

    // Validate fields only if not all fields are empty
    let hasError = false;
    // if (!isAllEmpty && !iNo1) {
    //   setInvoiceError("Invoice Number is required");
    //   hasError = true;
    // }
    if (!isAllEmpty && !paymentRecivedId1) {
      setPaymentTypeError("Payment Type is required");
      hasError = true;
    }

    if (hasError) {
      setUpdatePopup1(false);
      return; // Prevent submission if there are errors
    }
    setIsLoading1(true);

    try {
      const effectiveDate = date1 || setDefaultDate();
      const effectiveDate2 = date1Ig || setDefaultDate();

      // Construct paymentReceivedDetail only if there's at least one field with a non-default value
      const paymentReceivedDetail = [];
      if (!isAllEmpty) {
        paymentReceivedDetail.push({
          id: 0,
          invoiceNumber: iNo1 || "",
          paymentTermsId: paymentTermsId1 || 0,
          paymentReceivedDate: effectiveDate,
          currencyTypeId: currencyTypeId1 || 0,
          amount: amt1 || 0,
          paymentReceivedId: paymentRecivedId1 || 0,
          poAmount: poAmt1 || 0,
          remainingAmount: remainAmt || 0,
          totalReceivedAmount: totalAmt || 0,
          bankReferenceNumber: bankRefNo || "",
          invoiceGenerateDate: effectiveDate2,
          piNumber: selectedPiNumberPy.label || "",
          purchaseOrderPaymentReceivedImagesList: conAttach
            .filter(file => file.base64 || file.name) // Include only if base64 or name is present
            .map((file) => ({
              id: 0,
              imageFileName: file.name,
              imageOriginalFileName: file.name,
              image_Base64: file.base64,
            })),
        });
      }

      const requestBody1 = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 1,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: paymentReceivedDetail.length > 0 ? paymentReceivedDetail : undefined,
          lcReceivedDetail: [],
        },
      };

      console.log("Request Body: ", requestBody1);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token1}`,
        },
        body: JSON.stringify(requestBody1),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup1(false);
        setTimeout(() => {
          onCancel(); // Reload or navigate away
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading1(false);
    }
  };

  const formatDateTest2 = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // for 24-hour format
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Direct Payment / LC</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex.includes(0)}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex.includes(1)}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex.includes(2)}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Direct Payment / LC"}
            isOpen={openAccordionIndex.includes(3)}
            onClick={() => handleAccordionClick(3)}
          >
            <div className="mt-2 flex gap-4 items-center justify-between">
              <CustomCheckBox3
                label={"Direct Payment"}
                state={payCheck}
                setState={setPayCheck}
                disabled
              ></CustomCheckBox3>
            </div>

            {/* <EditPayment /> */}
            <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-3 w-[1000px]">
              <div className="">
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">
                    Currency Type
                  </label>
                  <Select
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                    menuPosition="fixed"
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <CustomInputNumberDecimal label="PO Amount" value={poAmt1} readOnly isRequired={false} />
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">PI Number</label>
                  <Select
                    options={piOptionsPy}
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={selectedPiNumberPy} // Show the selected value
                    // onChange={option => setSelectedPiNumberPy(option.label)} // Update selected value on change
                    onChange={handlePiNumber}
                    isClearable
                    menuPosition="fixed"
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="date" className="text-xs font-[400]">
                    Pi Issued Date
                  </label>
                  <input
                    type="datetime-local"
                    disabled
                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                    value={piIssuedDatePy}
                  />
                </div>
              </div>
              <div>
                <CustomInputNumberDecimal label="Total Received Amount" value={totalAmt} readOnly isRequired={false} />
              </div>
              <div>
                <CustomInputNumberDecimal label="Remaining Amount" value={remainAmt} readOnly isRequired={false} />
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">
                    Payment Terms
                  </label>
                  <Select
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                    menuPosition="fixed"
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              {/* <div className="">
                <div className="flex flex-col gap-1">
                  <label htmlFor="date" className="text-xs font-[400]">
                    Invoice Generated Date
                  </label>
                  <input
                    type="datetime-local"
                    // required
                    className={`p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs text-black bg-white`}
                    value={date1Ig}
                    onChange={(e) => setDate1Ig(e.target.value) || ""}
                    onBlur={() => {
                      if (!date1) setDate1(setDefaultDate());
                    }}
                  />
                </div>
              </div> */}
              {/* <div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="id" className="text-xs font-[400]">
                    Invoice Number<span className="text-red-500 gap-3">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={iNo1}
                    className={`p-2 border rounded grow min-w-[14rem] text-xs placeholder:text-xs uppercase `}
                    id="id"
                    placeholder={"Enter"}
                    onChange={handleChange}
                  // disabled={isFieldsDisabled} // Disable based on the condition
                  />
                  {invoiceError && <span className="text-red-500 text-xs">{invoiceError}</span>}
                </div>
              </div> */}
              <div className="">
                <div className="flex flex-col gap-1">
                  <label htmlFor="id" className="text-xs font-[400]">
                    Payment Received Date
                  </label>
                  <input
                    type="datetime-local"
                    className={`p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs text-black bg-white`}
                    id="id"
                    value={date1}
                    onChange={(e) => setDate1(e.target.value || "")}
                    onBlur={() => {
                      if (!date1) setDate1(setDefaultDate());
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="flex flex-col gap-1">
                  <label htmlFor="id" className="text-xs font-400">
                    Payment Type<span className="text-red-500 gap-3">*</span>
                  </label>
                  <Select
                    options={paymentRecivedData1.map((cData) => ({
                      value: cData.id,
                      label: cData.paymentReceived,
                    }))}
                    // onChange={(option) => setPaymentRecivedId1(option ? option.value : "")}
                    onChange={(option) => {
                      setPaymentRecivedId1(option ? option.value : "");
                      if (option) {
                        setPaymentTypeError(""); // Clear error when a valid value is selected
                      }
                    }}
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    isSearchable
                    isClearable
                    menuPosition={"fixed"}
                    closeMenuOnSelect={true}
                    // isDisabled={isFieldsDisabled} // Disable based on the condition
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: "#e5e7eb",
                      },
                    })}
                  />
                  {paymentTypeError && <span className="text-red-500 text-xs">{paymentTypeError}</span>}
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="amt1" className="text-xs font-[400]">Payment Received</label>
                  <input
                    type="number"
                    step="0.01"
                    className={`p-2 border rounded grow min-w-[14rem] text-xs text-black bg-white ${error1 ? 'border-red-500' : ''}`}
                    id="amt1"
                    value={amt1}
                    placeholder="Enter"
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // Allow digits and one decimal
                      handleAmt1Change(sanitizedValue);
                    }}
                  />
                  {error1 && <span className="text-red-500 text-xs">{error1}</span>}
                </div>
              </div>
              <div>
                <CustomTextField2 label="Bank Reference Number" placeholder="Enter" value={bankRefNo} onChange={setBankRefNo} isRequired={false} />
              </div>
              <div>
                <CustomImagePdfMultiple
                  label="Attachment"
                  id="poissuede435345ed4565it-edit-0"
                  value={conAttach}
                  isRequired={false}
                  onChange={handleConAttachChange}
                />
              </div>
            </div>

            <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
              <table className="w-full custom-table" border={1}>
                <thead>
                  <tr className="table-heading">
                    <td className="">S.No.</td>
                    <td>Action</td>
                    <td>Currency Type</td>
                    <td>PO Amount</td>
                    <td>Pi Number</td>
                    <td>Pi Issued Date</td>
                    <td>Total Received Amount</td>
                    <td>Remaining Amount</td>
                    <td>Payment Terms</td>
                    {/* <td>Invoice Number</td> */}
                    <td>Payment received Date</td>
                    <td>Payment Type</td>
                    <td>Payment Received</td>
                    <td>Bank Reference Number</td>
                    <td>Attachment</td>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentRecords1.length > 0 ? (
                    currentRecords1.map((item, index) => (
                      <tr key={index}>
                        <td className="text-xs">{startRecordIndex1 + index + 1}</td>
                        <td className="text-xs">
                          <div className="gap-2">
                            <Tooltip title="EDIT" arrow placement="bottom">
                              <span>
                                <CustomButton1
                                  className="bg-sixt text-white grow max-w-[50px]"
                                  icon={<BiEdit />}
                                  onClick={() => handleEditButtonClick1(item.id)}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="VIEW" arrow placement="bottom">
                              <span>
                                <CustomButton4
                                  className="bg-eye text-white grow max-w-[50px]"
                                  icon={<BsEyeFill />}
                                  onClick={() => handleViewButtonClick1(item.id)}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="DELETE" arrow placement="bottom">
                              <span>
                                <Button
                                  variant={"contained"}
                                  size={"small"}
                                  className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                  type={"button"}
                                  onClick={() => handleDeleteRecordPayment(item.id)}
                                >
                                  <RiDeleteBin6Line />
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                        <td className="text-xs">{item.currencyType}</td>
                        <td className="text-xs">{item.poAmount}</td>
                        <td className="text-xs">{item.piNumber}</td>
                        <td></td>
                        <td className="text-xs">{item.totalReceivedAmount}</td>
                        <td className="text-xs">{item.remainingAmount}</td>
                        <td className="text-xs">{item.paymentTerms}</td>
                        <td className="text-xs">{formatDateTest2(item.paymentReceivedDate)}</td>
                        {/* <td className="text-xs">{item.invoiceNumber}</td> */}
                        <td className="text-xs">{item.paymentReceived}</td>
                        <td className="text-xs">{item.amount}</td>
                        <td className="text-xs">{item.bankReferenceNumber}</td>
                        <td className="text-xs">
                          <Tooltip title="Open" arrow placement="bottom">
                            <div
                              className="underline cursor-pointer"
                              onClick={() => {
                                setRecordAttachment1(true);
                                setSelectedImages(item.purchaseOrderPaymentReceivedImagesList); // Pass images to the modal
                              }}
                            >
                              Click
                            </div>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="text-xs text-center p-2 ">No Payment received details available.</div>
                  )}
                </tbody>
              </table>

            </div>

            <div className="flex items-center justify-between mt-5">
              <div className="text-sm text-black font-normal">
                Displaying {startRecordIndex1 + 1} to {Math.min(endRecordIndex1, totalRecords1)} of {totalRecords1} records
              </div>

              <div className="flex gap-2">
                <div className="flex items-center">
                  <button
                    className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                    onClick={handlePrevPage1}
                    disabled={currentPage1 === 1}
                  >
                    Previous
                  </button>

                  <button
                    className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                    onClick={handleNextPage1}
                    disabled={currentPage1 === totalPages1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className=" max-w-full grid grid-cols-1  gap-x-2 md:grid-cols-3  w-[1000px] mt-5">
              <CustomCheckBox2
                label={"Payment / LC Closed"}
                state={prlCloseAll}
                setState={handleCheckboxChange}
              ></CustomCheckBox2>

              {prlCloseAll && (
                <CustomViewLabel
                  label="Payment / LC Closed Date & Time"
                  value={endFormatDate(endCloseDateTime)}
                />
              )}
            </div>
          </Accordion>
        </div>

        <div className="flex justify-center gap-5 pt-3">
          <div onClick={() => setUpdatePopup1(true)}>
            <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
          </div>
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>

      {recordEdit1 && (
        <PopUp>
          <PaymentReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord1}
            onCancel={() => {
              setRecordEdit1(false)
            }}
          />
        </PopUp>
      )}

      {
        recordAttachment1 && (
          <PopUp>
            <PaymentReceivedAttachmentImage
              selectedValue={selectedValue}
              selectedImages={selectedImages} // Pass images here
              record={selectedRecord1}
              onCancel={() => {
                setRecordAttachment1(false)
              }}
            />
          </PopUp>
        )
      }

      {recordView1 && (
        <PopUp>
          <PaymentReceivedEditInView
            record={selectedRecord1}
            onCancel={() => setRecordView1(false)}
          />
        </PopUp>
      )}

      {updatePopup1 && (
        <PopUp>
          <div className="pb-2 bg-white border rounded-lg">
            <div className="flex flex-col p-4">
              <p className="pb-5 text-sm">Are you sure you want to update ?</p>

              <div className="flex justify-end gap-2">
                <div onClick={handleSubmit1}>
                  <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                </div>
                <div onClick={() => setUpdatePopup1(false)}>
                  <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <ToastContainer autoClose={2000} />

    </>
  )
}
