// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewFinalBiIssued({ selectedValue }) {
    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };
    const fbI_FinalBIDraftIssueClosedDate = selectedValue?.fbI_FinalBIDraftIssueClosedDate ? new Date(selectedValue.fbI_FinalBIDraftIssueClosedDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-3 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Final BL Issued Date" value={formatDateTest1(selectedValue?.fbI_FinalBIDraftIssueClosedDate)} />
                    <CustomFileViewer fileURL={selectedValue?.fbI_FinalBIImageURL} fileName={selectedValue?.fbI_FinalBIOriginalFileName} label="Final BL Issued Upload" />
                    <CustomViewLabel label="Final BL Issued Close" value={selectedValue?.fbI_IsFinalBIDraftIssueClose} />
                    <CustomViewLabel label="Final BL Issued Close Date & Time" value={formatDateTest1(selectedValue?.fbI_FinalBIDraftIssueClosedDateTime)} />
                </div>
            </div>
        </>
    )
}

