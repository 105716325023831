// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewDocumentSend({ selectedValue }) {
    const ddS_DocumentSendDHL_SubmittedDate = selectedValue?.ddS_DocumentSendDHL_SubmittedDate ? new Date(selectedValue.ddS_DocumentSendDHL_SubmittedDate).toLocaleDateString('en-GB') : 'N/A';
    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="AWB NUMBER" value={selectedValue?.ddS_AWBNumber} />
                    <CustomViewLabel label="Document Send Dhl / Submitted Date" value={ddS_DocumentSendDHL_SubmittedDate} />
                    <CustomViewLabel label="Document Send Dhl / Submitted Close" value={selectedValue?.ddS_IsDocumentSendDHL_Submitted} />
                    <CustomViewLabel label="Document Send Dhl / Submitted Close Date & Time" value={formatDateTest1(selectedValue?.ddS_DocumentSendDHL_SubmittedClosedDate)} />
                </div>
            </div>
        </>
    )
}

