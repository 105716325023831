// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewBookingIssue({ selectedValue }) {
    const bI_BookingIssueAcceptedDate = selectedValue?.bI_BookingIssueAcceptedDate ? new Date(selectedValue.bI_BookingIssueAcceptedDate).toLocaleDateString('en-GB') : 'N/A';
    const bI_ETD = selectedValue?.bI_ETD ? new Date(selectedValue.bI_ETD).toLocaleDateString('en-GB') : 'N/A';
    const bI_ETA = selectedValue?.bI_ETA ? new Date(selectedValue.bI_ETA).toLocaleDateString('en-GB') : 'N/A';

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';
    
        const date = new Date(dateString);
        const options = { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="ETD" value={bI_ETD} />
                    <CustomViewLabel label="ETA" value={bI_ETA} />
                    <CustomFileViewer label={"Booking Issued Upload"} fileURL={selectedValue?.bI_ImageURL} fileName={selectedValue?.bI_OriginalFileName ?? ''} />
                    <CustomViewLabel label="Booking Issued Close" value={selectedValue?.bI_IsBookingIssueAccepted} />
                    <CustomViewLabel label="Booking Issued Close Date " value={formatDateTest1(selectedValue?.bI_BookingIssueAcceptedDate)} />
                </div>
            </div>
        </>
    )
}

