// CORE
import * as React from "react";
import { useState, useEffect } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomBrowse from "../../general-components/CustomBrowse.component";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoCheckmarkCircle, IoRemoveCircle } from "react-icons/io5";
import { BiDislike, BiLike } from "react-icons/bi";
import { Button, Tooltip } from "@mui/material";
import PopUp from "../../general-components/PopUp.componenet";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";

export default function ManagePOIssuedRejectEdit({
    selectedValue,
    onCancel,
}) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([1]);
    const [piConfirmedCheckbox, setPiConfirmedCheckbox] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [endCloseDateTime, setEndCloseDateTime] = useState(null);

    const endFormatDate = (date) =>
        date
            ? new Date(date).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // 12-hour format
            })
            : 'N/A';

    const handleCheckboxChange = (state) => {
        setPiConfirmedCheckbox(state);

        // Set the time every time the checkbox is flagged (true)
        if (state) {
            const timestamp = new Date().toISOString();
            setEndCloseDateTime(timestamp); // update time on each flag
        }
    };

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Format as YYYY-MM-DD HH:mm:ss with space between date and time
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        if (selectedValue && selectedValue.piIssuedList) {
            const formattedRows = selectedValue.piIssuedList.map((item) => ({
                id: item.id,
                piDate: formatDateForInput(item.piIssueDate),
                piNo: item.piNumber,
                // Assuming you want to get the first remark in the log list
                remarks: item.piIssuedLogList && item.piIssuedLogList.length > 0
                    ? item.piIssuedLogList[0].remarks
                    : "",
                attach: { base64: item.piImageURL, name: item.piOriginalFileName },
                initialAttach: { base64: item.piImageURL, name: item.piOriginalFileName },
                piImgUrl: item.piImageURL,
                piImgName: item.piOriginalFileName,
                statusId: item.statusId || 1  // Ensure statusId is set
            }));
            setRows(formattedRows);
        }
        if (selectedValue) {
            setPiConfirmedCheckbox(selectedValue.piC_IsConfirmed)

        }
    }, [selectedValue]);

    const handleRowChange1 = (index, field, value) => {
        const updatedRows = [...rows];
        const piNoValue = field === 'piNo' ? value.target.value : updatedRows[index].piNo;

        // Reset errors initially
        updatedRows[index].piDateError = '';
        updatedRows[index].piNoError = '';

        if (field === 'piNo') {
            if (piNoValue.trim() === '') {
                updatedRows[index].piNoError = 'PI Number is required';
            } else if (!/^[a-zA-Z0-9/-]*$/.test(piNoValue)) {
                updatedRows[index].piNoError = 'Only text, digits, slashes, and hyphens are allowed';
            }
        }

        if (field === 'piDate' && value.trim() === '') {
            updatedRows[index].piDateError = 'PI Issued Date is required';
        }

        updatedRows[index] = {
            ...updatedRows[index],
            [field]: field === 'piNo' ? piNoValue : value,
            // Do not automatically reset statusId here
        };

        setRows(updatedRows);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        const piNoValue = field === 'piNo' ? value.target.value : updatedRows[index].piNo;
    
        // Reset errors initially
        updatedRows[index].piDateError = '';
        updatedRows[index].piNoError = '';
    
        // Validate PI Number
        if (field === 'piNo') {
            if (piNoValue.trim() === '') {
                updatedRows[index].piNoError = 'PI Number is required';
            } else if (!/^[a-zA-Z0-9/-]*$/.test(piNoValue)) {
                updatedRows[index].piNoError = 'Only text, digits, slashes, and hyphens are allowed';
            }
        }
    
        // Validate PI Issued Date
        if (field === 'piDate' && value.trim() === '') {
            updatedRows[index].piDateError = 'PI Issued Date is required';
        }
    
        // Find the original row to compare changes
        const originalRow = selectedValue.piIssuedList.find(row => row.id === updatedRows[index].id);
    
        // Check if any specific fields (excluding statusId) have changed
        const hasFieldChanged =
            (field === 'piDate' && originalRow.piIssueDate !== value) ||
            (field === 'piNo' && originalRow.piNumber !== piNoValue) ||
            (field === 'attach' && (value.base64 !== updatedRows[index].initialAttach.base64 || value.name !== updatedRows[index].initialAttach.name)) ||
            (field === 'remarks' && originalRow.remarks !== value);
    
        // Update the row data
        updatedRows[index] = {
            ...updatedRows[index],
            [field]: field === 'piNo' ? piNoValue : value,
            statusId: hasFieldChanged
                ? 1 // Set statusId to 1 if any of the tracked fields have changed
                : updatedRows[index].statusId, // Otherwise, retain the current statusId
        };
    
        setRows(updatedRows);
    };
    
    const handleStatusChange = (index, newStatusId) => {
        const updatedRows = [...rows];
        updatedRows[index] = {
            ...updatedRows[index],
            statusId: newStatusId
        };
        setRows(updatedRows);

        if (newStatusId === 2) {
            setPiConfirmedCheckbox(true);
            setEndCloseDateTime(new Date().toISOString()); // Set confirmation timestamp
        }
    };
    const formatDateForInput1 = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString(); // Formats date to ISO 8601 with "T" and "Z"
    };

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const allAccepted = rows.every((row) => row.statusId === 2);
        const allRejected = rows.every((row) => row.statusId === 3);

        // if (!allAccepted && !allRejected) {
        //     console.error("All rows must have the same status, either 'Accept' or 'Reject'.");
        //     toast.error("All rows must have the same status, either 'Accept' or 'Reject'.");
        //     setIsLoading(false);
        //     return;
        // }

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId,
                piIssuedList: rows.map((row) => ({
                    id: row.id,
                    piIssueDate: formatDateForInput1(row.piDate),  // Pass the formatted date
                    piNumber: row.piNo,       // Pass the piNumber
                    piImage: "",              // Assuming you're not passing the image in the request
                    piImage_Base64: row.attach.base64 === row.initialAttach.base64 ? "" : row.attach.base64,
                    piOriginalFileName: row.attach.name === row.initialAttach.name ? "" : row.attach.name,
                    remark: row.remarks || "",
                    statusId: row.statusId,
                })),
                piC_IsConfirmed: piConfirmedCheckbox,
            };

            console.log("requestbody-->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Check if all rows are accepted whenever rows state changes
        const allAccepted = rows.length > 0 && rows.every((row) => row.statusId === 2);
      
        if (allAccepted) {
          console.log("All rows are accepted. Triggering API submission...");
          handleSubmit({ preventDefault: () => {} }); // Automatically call handleSubmit
        }
      }, [rows]); // Dependency array includes rows

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Rejected PI Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Rejected PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <div className="max-w-full w-[1000px]">
                            {rows.map((row, index) => (
                                <div key={index} className="max-w-full grid grid-cols-1  gap-x-2 md:grid-cols-3  w-[1000px] mt-5">
                                    <div >
                                        {/* <CustomViewLabel label="PI Issued" value={row.piDate} /> */}
                                        <div className="flex flex-col gap-1">
                                            <label htmlFor="date" className="text-xs font-[400]">
                                                PI Issued Date<span className="text-red-500 gap-3">*</span>
                                            </label>
                                            <input
                                                type="datetime-local"
                                                required
                                                className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                                id={`pi-issued-${index}`}
                                                value={row.piDate}
                                                onChange={(e) => handleRowChange(index, 'piDate', e.target.value)}
                                                disabled={row.statusId === 2}
                                            />
                                            {row.piDateError && <span className="text-red-500 text-xs">{row.piDateError}</span>}
                                        </div>
                                    </div>
                                    <div >
                                        {/* <CustomViewLabel label="PI Number" value={row.piNo} /> */}
                                        <div>
                                            <div className="flex flex-col gap-1">
                                                <label htmlFor="id" className="text-xs font-[400]">
                                                    PI Number<span className="text-red-500 gap-3">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    value={row.piNo}
                                                    className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                                    id="id"
                                                    placeholder="Enter"
                                                    disabled={row.statusId === 2}
                                                    onChange={(value) => handleRowChange(index, 'piNo', value)}
                                                />
                                                {row.piNoError && <span className="text-red-500 text-xs">{row.piNoError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div >
                                        <div>
                                            {/* {index < selectedValue?.piIssuedList?.length ? (
                                                <CustomFileViewer
                                                    fileURL={row.piImgUrl}
                                                    fileName={row.piImgName}
                                                    label="PI Confirmation Upload" />
                                            ) : (""
                                            )} */}

                                            {index < selectedValue.piIssuedList.length ? (
                                                <CustomFileUpload
                                                    id={`poissuededit-edit-${index}`}
                                                    label="PI Upload"
                                                    value={row.attach}
                                                    onChange={(value) => handleRowChange(index, 'attach', value)}
                                                    initialFileName={row.piImgName}
                                                    initialFileUrl={row.piImgUrl}
                                                    isRequired={false}
                                                />
                                            ) : (
                                                <CustomBrowse
                                                    label="PI Upload"
                                                    id={`poissuededit-edit-${index}`}
                                                    value={row.attach}
                                                    isRequired={false}
                                                    onChange={(value) => handleRowChange(index, 'attach', value)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div >
                                        <div>
                                            {index < selectedValue.piIssuedList.length ? (
                                                <>
                                                    <div className=" flex gap-5 items-center mt-5">
                                                        <label htmlFor="uname" className="text-xs font-[400]">
                                                            Status :
                                                        </label>
                                                        {row.statusId === 1 ? (
                                                            <p className="text-xs border border-yellow-500 modal-btn-comman text-yellow-500">Pending</p>
                                                        ) : row.statusId === 2 ? (
                                                            <Tooltip title="ACCEPT" arrow placement="bottom">
                                                                <span>
                                                                    <IoCheckmarkCircle className="h-9 w-9 text-green-500" />
                                                                </span>
                                                            </Tooltip>
                                                        ) : row.statusId === 3 ? (
                                                            <Tooltip title="REJECT" arrow placement="bottom">
                                                                <span>
                                                                    <IoRemoveCircle className="h-10 w-9 text-red-500" />
                                                                </span>
                                                            </Tooltip>
                                                        ) : null}

                                                        <Tooltip title="ACCEPT" arrow placement="bottom">
                                                            <span>
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    className={`bg-green-500 text-white grow max-w-[30px] flex gap-2 justify-center items-center relative uppercase`}
                                                                    onClick={() => handleStatusChange(index, 2)} // Like button
                                                                >
                                                                    <BiLike />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="REJECT" arrow placement="bottom">
                                                            <span>
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    className={`bg-red-500 text-white grow max-w-[30px] flex gap-2 justify-center items-center relative uppercase`}
                                                                    onClick={() => handleStatusChange(index, 3)} // Dislike button
                                                                >
                                                                    <BiDislike />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    </div>
                                    <div >
                                        <div>
                                            {/* <CustomTextField2 label="Remarks " value={row.remarks} /> */}
                                            <div className="flex flex-col gap-1">
                                                <label htmlFor="id" className="text-xs font-[400]">
                                                    Remarks
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase text-black`}
                                                    id="id"
                                                    placeholder={"Enter"}
                                                    value={row.remarks || ""}
                                                    onChange={(e) => {
                                                        const updatedRows = [...rows];
                                                        updatedRows[index].remarks = e.target.value;
                                                        setRows(updatedRows);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="max-w-full grid grid-cols-1  gap-x-2 md:grid-cols-3  w-[1000px] mt-5">
                            <CustomCheckBox2
                                label={"PI Confirmation"}
                                state={piConfirmedCheckbox}
                                setState={handleCheckboxChange}
                            />
                            {piConfirmedCheckbox && (
                                <CustomViewLabel
                                    label="PI Confirmation Date & Time"
                                    value={endFormatDate(endCloseDateTime)}
                                />
                            )}
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
