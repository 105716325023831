// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// ICONSbg-white
import { BiEdit } from "react-icons/bi";
import Title from "../../components/general-components/Title.component";
import CustomButton1 from "../../components/general-components/CustomButton1.component";
import PopUp from "../../components/general-components/PopUp.componenet";
import CustomDate from "../../components/general-components/CustomDate.component";
import CustomSelect1 from "../../components/general-components/CustomSelect1.component";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ManageFinalInvoicePaymentRecordEdit from "../../components/forms-components/ManageFinalInvoicePaymentRecord/ManageFinalInvoicePaymentRecordEdit";
import Pagination from "../../components/general-components/Pagination.component";

export default function ManageFinalInvoicePaymentRecord() {
    let [popupEdit, setPopupEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [paperTData, setPaperTData] = useState([]);
    const [selectedPaperTId, setSelectedPaperTId] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([
        { sno: 1, piNo: "Lorem ipsum", consignee: "Lorem ipsum", port: "Lorem ipsum", schedule: "Lorem ipsum" },
        { sno: 2, piNo: "Lorem ipsum", consignee: "Lorem ipsum", port: "Lorem ipsum", schedule: "-" },
        // Add more rows as needed
    ]);

    useEffect(() => {
        setTotalPages(Math.ceil(data.length / rowsPerPage));
    }, [data, rowsPerPage]);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaperTypeList`, setPaperTData, "Error fetching Paper Type Data");
    }, []);

    const handlePaperTChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedPaperTId(selectedOption.value);
        } else {
            setSelectedPaperTId("");
        }
    }

    return (
        <>
            <div className="flex flex-col h-full p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"MIS Final Invoice, Payment Record"} title2={"Manage Report"} />

                    <div className=" bg-white rounded grow">
                        <div className="px-2 lg:px-4">
                            {/* 4 BUTTONS */}
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-5 minbox">
                                <div>
                                    <CustomDate
                                        label="From Date : "
                                        placeholder="Enter"
                                        value={fromDate}
                                        onChange={setFromDate}
                                    />
                                </div>
                                <div>
                                    <CustomDate
                                        label="To Date : "
                                        placeholder="Enter"
                                        value={toDate}
                                        onChange={setToDate}
                                    />
                                </div>
                                <div>
                                </div>
                                <div>
                                    <CustomButton1 label={"Search"} className="text-white bg-prp-color w-full" type="button" />
                                </div>
                                <div>
                                    <CustomButton1 label={"Reset"} className="text-white bg-prp-color w-full" type="button" />
                                </div>
                                <div>
                                    <CustomButton1 label={"Export"} className="text-white bg-prp-color w-full" type="button" />
                                </div>

                            </div>

                            {/* TABLE */}
                            <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                <table className="w-full custom-table" border={1}>
                                    <thead>
                                        <tr className="table-heading">
                                            <td className="">S.No</td>
                                            <td>Action</td>
                                            <td>INVOICE NO.</td>
                                            <td>INVOICE DATE</td>
                                            <td>PI NO. </td>
                                            <td>CONSIGNEE </td>
                                            <td>NOTIFY PARTY </td>
                                            <td>PORT OF DISCHARGE </td>
                                            <td>DESTINATION COUNTRY</td>
                                            <td>DUPLEX / KRAFT </td>
                                            <td>TYPE OF PACKING </td>
                                            <td>BRAND </td>
                                            <td>DELIVERY TERM </td>
                                            <td>FINAL QTY. </td>
                                            <td>CIF/CFR VALUE </td>
                                            <td>FOB VALUE </td>
                                            <td>FREIGHT </td>
                                            <td>PAYMENT TERM </td>
                                            <td>REMITTED AMOUNT </td>
                                            <td>DATE </td>
                                            <td>Port Code </td>
                                            <td>SB No </td>
                                            <td>DATE </td>
                                            <td>Created Date </td>
                                            <td>Created By </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <div>
                                                    <CustomButton1
                                                        className="bg-sixt text-white grow max-w-[50px]"
                                                        icon={<BiEdit />}
                                                        onClick={() => setPopupEdit(true)}
                                                    />
                                                </div>
                                            </td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>                                            
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>10/08/2023</td>
                                            <td>10/08/2023</td>
                                            <td>Admin</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <div>
                                                    <CustomButton1
                                                        className="bg-sixt text-white grow max-w-[50px]"
                                                        icon={<BiEdit />}
                                                        onClick={() => setPopupEdit(true)}
                                                    />
                                                </div>
                                            </td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>                                            
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>Lorem ipsum</td>
                                            <td>10/08/2023</td>
                                            <td>10/08/2023</td>
                                            <td>Admin</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                            <Pagination
                                currentPage={currentPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                data={data}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {popupEdit && (
                <PopUp>
                    <ManageFinalInvoicePaymentRecordEdit
                        onCancel={() => setPopupEdit(false)}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    );
}



