import React, { useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomDate from './../../general-components/CustomDate.component';
import CustomCheckBox2 from './../../general-components/CustomCheckBox2.component';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';

const options = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },
    { value: "5", label: "Option 5" },
    { value: "6", label: "Option 6" },
    { value: "7", label: "Option 7" },
    { value: "8", label: "Option 8" },
    { value: "9", label: "Option 9" },
    { value: "10", label: "Option 10" },
];

export default function ManageSBillRecordEdit({ onCancel }) {
    const [testCheck, setTestCheck] = useState(true);
    const [testCheck1, setTestCheck1] = useState(false);
    const [testCheck2, setTestCheck2] = useState(false);
    const [testCheck3, setTestCheck3] = useState(false);

    const [test1, setTest1] = useState("");
    const [test2, setTest2] = useState("");
    const [test3, setTest3] = useState("");
    const [test4, setTest4] = useState("");
    const [test5, setTest5] = useState("");
    const [test6, setTest6] = useState("");
    const [test7, setTest7] = useState("");
    const [test8, setTest8] = useState("");
    const [test9, setTest9] = useState("");
    const [test10, setTest10] = useState("");
    const [test11, setTest11] = useState("");
    
    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit S. Bill Record</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[900px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                        <CustomInputNumber label="SB No" placeholder="Enter" isRequired={false}
                            value={test3}
                            onChange={setTest3}
                        />
                        <CustomDate label="Date" placeholder="Enter" isRequired={false}
                          value={test2}
                          onChange={setTest2}
                        />

                        <CustomDate label="LEO DATE" placeholder="Enter" isRequired={false}
                            value={test1}
                            onChange={setTest1}
                        />

                        <CustomInputNumber label="Port Code" placeholder="Enter" isRequired={false}
                            value={test7}
                            onChange={setTest7}
                        />

                        {/* <CustomInputNumber label="CIF/CFR VALUE" placeholder="Enter" isRequired={false}
                            value={test2}
                            onChange={setTest2}
                        /> */}
                        <CustomTextField2 label="FREIGHT" placeholder="Enter" isRequired={false}
                            value={test4}
                            onChange={setTest4}
                        />
                        {/* <CustomInputNumber label="FOB VALUE" placeholder="Enter" isRequired={false}
                            value={test5}
                            onChange={setTest5}
                        /> */}

                        {/* <CustomInputNumber label="FOB VALUE(RS.)" placeholder="Enter" isRequired={false}
                            value={test8}
                            onChange={setTest8}
                        /> */}

                        <CustomInputNumber label="EXCHANGE RATE" placeholder="Enter" isRequired={false}
                            value={test6}
                            onChange={setTest6}
                        />
                        <CustomCheckBox2
                            label={"BRC IN BANK"}
                            state={testCheck}
                            setState={setTestCheck}
                        ></CustomCheckBox2>
                        <CustomCheckBox2
                            label={"BRC IN DGFT"}
                            state={testCheck1}
                            setState={setTestCheck1}
                        ></CustomCheckBox2>

                        {/* <CustomInputNumber label="DBK VALUE" placeholder="Enter" isRequired={false}
                            value={test9}
                            onChange={setTest9}
                        /> */}

                         <CustomCheckBox2
                            label={"DBK RECEIVED"}
                            state={testCheck3}
                            setState={setTestCheck3}
                        ></CustomCheckBox2>

                        <CustomInputNumberDecimal label="IGST AMOUNT" placeholder="Enter" isRequired={false}
                            value={test11}
                            onChange={setTest11}
                        />
                        <CustomCheckBox2
                            label={"IGST RECEIVED"}
                            state={testCheck2}
                            setState={setTestCheck2}
                        ></CustomCheckBox2>
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={onCancel}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>
        </>
    )
}


